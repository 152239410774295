import * as cfg from '../Helper/config.js';
import * as hlp from '../Helper/Helper.js';

export const getAddCakeLMCoinActions = function (data) {
  return data.filter(el =>
    hlp.checkActions(cfg.CAKE_LM_ADD_COIN, el.Operation)
  );
};

export const getAddCakeLMTokenActions = function (data) {
  return data.filter(el =>
    hlp.checkActions(cfg.CAKE_LM_ADD_TOKEN, el.Operation)
  );
};

export const getRemCakeLMCoinActions = function (data) {
  return data.filter(el =>
    hlp.checkActions(cfg.CAKE_LM_REMOVE_COIN, el.Operation)
  );
};

export const getRemCakeLMTokenActions = function (data) {
  return data.filter(el =>
    hlp.checkActions(cfg.CAKE_LM_REMOVE_TOKEN, el.Operation)
  );
};

export const getAddDefiLMActions = function (data) {
  return data.filter(el => hlp.checkActions(cfg.DEFI_LM_ADD, el.Operation));
};
export const getRemDefiLMActions = function (data) {
  return data.filter(el => hlp.checkActions(cfg.DEFI_LM_REMOVE, el.Operation));
};

/**
 * Sorts an array of data by date
 * @param {Object} objA - Object A to check
 * @param {Object} objB - Object B to check
 * @returns the correct value for Array.sort
 */
export const sortDateAscending = function (objA, objB) {
  if (objA.Date.getTime() < objB.Date.getTime()) {
    return -1;
  }
  if (objA.Date.getTime() > objB.Date.getTime()) {
    return 1;
  }
  // a muss gleich b sein
  return 0;
};
