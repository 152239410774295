import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';
import * as lmTool from './LMTools.js';
import * as Actions from '../Actions/Actions.js';
import * as mdl from '../model.js';

/**
 * Checks and tries to calculate the missing LM entries
 * @param {Array} entryArr - Array with the incomplete LM entries
 * @returns the completed LM transaction entry
 */
const checkAndCalculateMissingTransaction = function (entryArr) {
  const retArr = [];
  let indexOfToken = -1;
  let indexOfDFI = -1;
  let indexOfDUSD = -1;
  let indexOfOtherCoin = -1;

  for (let i = 0; i < entryArr.length; i++) {
    if (entryArr[i].Cryptocurrency.indexOf('-') != -1) {
      //LM Token found
      indexOfToken = i;
    } else if (entryArr[i].Cryptocurrency === 'DFI') {
      indexOfDFI = i;
    } else if (entryArr[i].Cryptocurrency === 'DUSD') {
      indexOfDUSD = i;
    } else {
      indexOfOtherCoin = i;
    }
  }
  let newEntry = {};
  newEntry['Block'] = entryArr[0].Block;
  newEntry['BlockHash'] = entryArr[0].BlockHash;
  newEntry['Operation'] = entryArr[0].Operation;
  newEntry['PoolID'] = entryArr[0].PoolID;
  newEntry['Date'] = entryArr[0].Date;

  if (entryArr.length === 2) {
    console.log(entryArr);
    if (indexOfToken === -1) {
      //Token is missing
      newEntry.Amount = Math.sqrt(entryArr[0].Amount * entryArr[1].Amount);
      if (indexOfDFI === -1) {
        newEntry.Cryptocurrency = `${entryArr[indexOfOtherCoin].Cryptocurrency}-${entryArr[indexOfDUSD].Cryptocurrency}`;
      } else {
        if (indexOfDUSD === -1) {
          newEntry.Cryptocurrency = `${entryArr[indexOfOtherCoin].Cryptocurrency}-${entryArr[indexOfDFI].Cryptocurrency}`;
        } else {
          newEntry.Cryptocurrency = `${entryArr[indexOfDFI].Cryptocurrency}-${entryArr[indexOfDUSD].Cryptocurrency}`;
        }
      }
    } else {
      //One coin is missing
      if (indexOfToken === 1) {
        newEntry.Amount =
          (entryArr[1].Amount * entryArr[1].Amount) / entryArr[0].Amount;
        newEntry.Cryptocurrency = entryArr[1].Cryptocurrency;
      } else {
        newEntry.Amount =
          (entryArr[0].Amount * entryArr[0].Amount) / entryArr[1].Amount;
        newEntry.Cryptocurrency = entryArr[0].Cryptocurrency;
      }
    }
  }
  newEntry.Owner = 'GENERATEDBYDEFICHAINREWARDHELPER';
  retArr.push(...entryArr);
  retArr.push(newEntry);

  return retArr;
};

/**
 * Determines alle LiquidityMining Actions (Add- / Remove-Pool)
 * @param {Array} lmData - Data with all transactions
 * @returns the array with all liquiditymining actions
 */
const getLMActionsArray = function (lmData) {
  //Set mit allen beteiligten Blöcken erstellen
  const blockHeight = new Set();
  lmData.forEach(el => {
    blockHeight.add(el.Block);
  });
  let arrOfEntryArr = [];
  //Für jeden beteiligten Block die Transaktionen ermitteln
  blockHeight.forEach(blockEl => {
    let entryArr = lmData.filter(entry => entry.Block === blockEl);
    if (entryArr.length > 3) {
      if (mdl.state.directBlockchain) {
        const txIds = new Set();
        entryArr.forEach(el => txIds.add(el.TxID));
        txIds.forEach(txEl => {
          let newFilter = entryArr.filter(entry => entry.TxID === txEl);
          if (newFilter.length === 3) {
            arrOfEntryArr.push(newFilter);
          } else {
            console.log('Undefined ERROR!!!');
            console.log(newFilter);
          }
        });
      } else {
        const poolStr = new Set();
        entryArr.forEach(el => poolStr.add(el.Pool));
        poolStr.forEach(poolEl => {
          let newFilter = entryArr.filter(entry => entry.Pool === poolEl);
          //If there are more than 3 actions within a block where not all actions are exported in "one line" we have to investigate a bit more
          if (newFilter.length > 3) {
            //Create a unique list with all involved Pools

            const suggestedPools = new Set();
            newFilter.forEach(el => {
              if (el.PoolSuggestion.length > 0) {
                suggestedPools.add(el.PoolSuggestion);
              }
            });
            suggestedPools.forEach(pool => {
              //Filter all elements with the same pool suggestion
              let entries = newFilter.filter(el => el.PoolSuggestion === pool);
              //If result contains 3 entries, everything is fine
              if (entries.length === 3) {
                arrOfEntryArr.push(entries);
              } else {
                //Is not, there was a problem with the assignment
                if (entries.length === 2) {
                  //One entry (DFI is missing)
                  let arrNotClear = newFilter.filter(
                    entry => entry.PoolSuggestion === ''
                  );
                  //If array with unclear elements is one 1 element long, everything is clear
                  if (arrNotClear.length === 1) {
                    entries.push(...arrNotClear);
                    arrOfEntryArr.push(entries);
                  } else {
                    //If not.. we have to do some more research
                    let fiatValue;
                    //Get the FIAT value from the other pool pair coin
                    if (entries[0].Cryptocurrency.length > 4) {
                      fiatValue = entries[1].Amount * entries[1].FiatPriceAPI;
                    } else {
                      fiatValue = entries[0].Amount * entries[0].FiatPriceAPI;
                    }
                    //Get all prices of the unclear elements...
                    arrNotClear.forEach(el => {
                      let lowerValue = Math.abs(fiatValue - fiatValue * 0.08);
                      let upperValue = Math.abs(fiatValue * 1.08);
                      let checkValue = Math.abs(el.Amount * el.FiatPriceAPI);
                      //console.log(`Folgende Werte: Lower: ${lowerValue} // Check: ${checkValue} // Upper: ${upperValue}`);
                      //...and check if the value fits to the other values
                      if (
                        checkValue >= lowerValue &&
                        checkValue <= upperValue
                      ) {
                        //If value fits, add the unclear element to the other entries
                        entries.push(el);
                        arrOfEntryArr.push(entries);
                      }
                    });
                  }
                } else {
                  //This means, we have a Problem with the suggestion...
                  console.log('Undefined ERROR!!!');
                  console.log(entries);
                }
              }
            });
            //Check if all entries are processed
            //TBD.
          } else {
            arrOfEntryArr.push(newFilter);
          }
        });
      }
    } else {
      if (entryArr.length < 3) {
        const newEntry = checkAndCalculateMissingTransaction(entryArr);
        arrOfEntryArr.push(newEntry);
      } else {
        arrOfEntryArr.push(entryArr);
      }
    }
  });

  return arrOfEntryArr;
};

/**
 *
 * @param {*} addData
 * @param {*} removeData
 */
const generateDeFiLMActionsWithToken = async function (
  addData,
  removeData,
  tool
) {
  let objData = [];
  //Generate LM Actions for DeFiApp file
  if (addData.length > 0 || removeData.length > 0) {
    hlp.logconsole('Generating LM actions for DeFi App');
    let allLiq = [...addData, ...removeData];
    allLiq = hlp.removeDublicates(allLiq);
    const arrOfEntryArr = getLMActionsArray(allLiq);
    //console.log(arrOfEntryArr);
    arrOfEntryArr.forEach(entryArr => {
      let addString = '';
      //Must have length 3 (Coin1, Coin2, LM-Token)
      //Length === 3 --> Everything is fine!
      if (entryArr.length === 3) {
        entryArr.forEach(el => {
          if (el.Owner === 'GENERATEDBYDEFICHAINREWARDHELPER') {
            addString = '- Entry was calculated by DeFiChain-Rewardhelper!';
          }
        });
        let coinsAmount = [];
        let lmAmount;
        let coinsCur = [];
        let lmCur;
        const entryDate = entryArr[0].Date;
        if (entryArr[0].Operation === 'AddPoolLiquidity') {
          let feeAmnt = Math.abs(entryArr[0].Fee).toFixed(14);
          let feeCurrency = entryArr[0].FeeCurrency;

          //<0 --> Coin || >0 --> Token
          entryArr.forEach(el => {
            if (el.Amount < 0) {
              coinsAmount.push(Math.abs(el.Amount).toFixed(14));
              coinsCur.push(el.Cryptocurrency);
            } else {
              lmAmount = Math.abs(el.Amount).toFixed(14);
              lmCur = el.Cryptocurrency;
            }
          });
          if (tool === 'Cointracking') {
            //Coin1 --> Provide Liquidity (send)
            //Trade 1
            objData.push(
              Actions.buildEntryObj(
                'Provide Liquidity',
                '',
                '',
                '',
                '',
                '',
                coinsAmount[0],
                coinsCur[0],
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                'Add Liquidity',
                `Coin1 Provide Liquidity ${addString}`,
                entryDate,
                `${coinsCur[0]}${dateHelper.getTxIdDateString(
                  entryDate
                )}_TxID:${entryArr[0].TxID}`,
                ''
              )
            );
            //Coin2 --> Provide Liquidity (send)
            objData.push(
              Actions.buildEntryObj(
                'Provide Liquidity',
                '',
                '',
                '',
                '',
                '',
                coinsAmount[1],
                coinsCur[1],
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                'Add Liquidity',
                `Coin2 Provide Liquidity ${addString}`,
                entryDate,
                `${coinsCur[1]}${dateHelper.getTxIdDateString(
                  entryDate
                )}_TxID:${entryArr[0].TxID}`,
                ''
              )
            );
            //Token--> Receive LP Token (rec)
            objData.push(
              Actions.buildEntryObj(
                'Receive LP Token',
                '',
                '',
                '',
                lmAmount,
                lmCur,
                '',
                '',
                '',
                '',
                'DeFiChain Wallet',
                'Receive LP Token',
                `Receive Liquidity Token ${addString}`,
                entryDate,
                `${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
          } else {
            let actionName = 'Add Liquidity';
            if (tool === 'Koinly') {
              actionName = 'Liquidity in';
            }
            //Trade 1
            objData.push(
              Actions.buildEntryObj(
                'Trade',
                'trade',
                'order',
                '',
                lmAmount / 2,
                lmCur,
                coinsAmount[0],
                coinsCur[0],
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                actionName,
                `Trade 1 ${addString}`,
                entryDate,
                `1${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
            //Trade 2
            objData.push(
              Actions.buildEntryObj(
                'Trade',
                'trade',
                'order',
                '',
                lmAmount / 2,
                lmCur,
                coinsAmount[1],
                coinsCur[1],
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                actionName,
                `Trade 2 ${addString}`,
                entryDate,
                `2${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
          }
        } else {
          let feeAmnt = Math.abs(entryArr[0].Fee).toFixed(14);
          let feeCurrency = entryArr[0].FeeCurrency;
          //>0 --> Coin || <0 --> Token
          entryArr.forEach(el => {
            if (el.Amount > 0) {
              coinsAmount.push(Math.abs(el.Amount).toFixed(14));
              coinsCur.push(el.Cryptocurrency);
            } else {
              lmAmount = Math.abs(el.Amount).toFixed(14);
              lmCur = el.Cryptocurrency;
            }
          });
          if (tool === 'Cointracking') {
            //Coin1 --> Provide Liquidity (send)
            //Trade 1
            objData.push(
              Actions.buildEntryObj(
                'Remove Liquidity',
                '',
                '',
                '',
                coinsAmount[0],
                coinsCur[0],
                '',
                '',
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                'Remove Liquidity',
                `Coin1 Remove Liquidity ${addString}`,
                entryDate,
                `${coinsCur[0]}${dateHelper.getTxIdDateString(
                  entryDate
                )}_TxID:${entryArr[0].TxID}`,
                ''
              )
            );
            //Coin2 --> Provide Liquidity (send)
            objData.push(
              Actions.buildEntryObj(
                'Remove Liquidity',
                '',
                '',
                '',
                coinsAmount[1],
                coinsCur[1],
                '',
                '',
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                'Remove Liquidity',
                `Coin2 Remove Liquidity ${addString}`,
                entryDate,
                `${coinsCur[1]}${dateHelper.getTxIdDateString(
                  entryDate
                )}_TxID:${entryArr[0].TxID}`,
                ''
              )
            );
            //Token--> Receive LP Token (rec)
            objData.push(
              Actions.buildEntryObj(
                'Return LP Token',
                '',
                '',
                '',
                '',
                '',
                lmAmount,
                lmCur,
                '',
                '',
                'DeFiChain Wallet',
                'Return LP Token',
                `Return Liquidity Token ${addString}`,
                entryDate,
                `${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
          } else {
            let actionName = 'Remove Liquidity';
            if (tool === 'Koinly') {
              actionName = 'Liquidity out';
            }
            //Trade zusammenstellen
            //Trade 1
            objData.push(
              Actions.buildEntryObj(
                'Trade',
                'trade',
                'order',
                '',
                coinsAmount[0],
                coinsCur[0],
                lmAmount / 2,
                lmCur,
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                actionName,
                `Trade 1 ${addString}`,
                entryDate,
                `1${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
            //Trade 2
            objData.push(
              Actions.buildEntryObj(
                'Trade',
                'trade',
                'order',
                '',
                coinsAmount[1],
                coinsCur[1],
                lmAmount / 2,
                lmCur,
                feeAmnt / 2,
                feeCurrency,
                'DeFiChain Wallet',
                actionName,
                `Trade 2 ${addString}`,
                entryDate,
                `2${lmCur}${dateHelper.getTxIdDateString(entryDate)}_TxID:${
                  entryArr[0].TxID
                }`,
                ''
              )
            );
          }
        }
      } else {
        alert(
          `It seems that there is a problem with the Liquidity Minining in Block ${entryArr[0].Block}! Please ensure, that all addresses are processed at the same time. Otherwise please contact Marcus!`
        );
        console.log(
          `Error in generating LiquidityMining entries for Block ${entryArr[0].Block}`
        );
      }
    });
  }
  return objData;
};

/**
 * Generates the defichain liquidity mining entries
 * @param {Array} data
 * @param {String} toolid
 
 */
export const generateDeFiLMActions = async function (data, tool) {
  let objData = [];
  let addDefiLMActions = lmTool.getAddDefiLMActions(data);
  let remDefiLMActions = lmTool.getRemDefiLMActions(data);

  objData = generateDeFiLMActionsWithToken(
    addDefiLMActions,
    remDefiLMActions,
    tool
  );

  return objData;
};
