import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateDeFiVaultsActions = function (data) {
  let objData = [];
  hlp.logconsole('Generating DeFiVault Actions');

  const depositToVault = data.filter(el => el.Operation === 'DepositToVault');
  const takeLoan = data.filter(el => el.Operation === 'TakeLoan');
  const paybackLoan = data.filter(el => el.Operation === 'PaybackLoan');
  const withdrawFromVault = data.filter(
    el => el.Operation === 'WithdrawFromVault'
  );

  const closeVault = data.filter(el => el.Operation === 'CloseVault');

  //Deposit to Vault-Actions
  depositToVault.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet',
        'DepositToVault - Withdraw from DefiChain Wallet',
        'DepositToVault',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'DeFiChain Wallet - Vault',
        'DepositToVault - Deposit to Vault Wallet',
        'DepositToVault',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //Take Loan Actions
  takeLoan.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        'add funds',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'DeFiChain Wallet',
        'TakeLoan - Minted Token',
        'TakeLoan',
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //Payback Loan Actions
  paybackLoan.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet',
        'PaybackLoan - Withdraw from DefiChain Wallet',
        'PaybackLoan',
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //Withdrawal From Vault-Actions
  withdrawFromVault.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet - Vault',
        'WithdrawFromVault - Withdraw from Vault Wallet',
        'WithdrawFromVault',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'DeFiChain Wallet',
        'WithdrawFromVault - Deposit to DeFiChain Wallet',
        'WithdrawFromVault',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });
  //Close Vault Actions
  closeVault.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'DeFiChain Wallet',
        'CloseVault - Close Vault',
        'CloseVault',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet - Vault',
        'CloseVault - Close Vault',
        'CloseVault',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });
  return objData;
};
