import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * This function filters all withdrawal actions from the DFIP2203 list
 * @param {Array} rawPlaceAuctionBidList - List with all DFIP2203 actions (set and withdrawal)
 * @returns the list with DFIP2203 actions to be executed by FutureSwapExecution
 */
const getCorrectAuctionBidList = function (rawPlaceAuctionBidList) {
  let posList = [];
  let negList = [];
  rawPlaceAuctionBidList.forEach(el => {
    if (el.Amount < 0) {
      negList.push(el);
    } else {
      posList.push(el);
    }
  });
  posList.forEach(el => {
    let idx = negList.findIndex(
      el2 => Math.abs(el.Amount) === Math.abs(el2.Amount)
    );
    negList.splice(idx, 1);
  });
  return negList;
};

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateDeFiAuctionActions = function (data) {
  let objData = [];
  hlp.logconsole('Generating DeFiAuction Actions');

  const placeAuctionBids = data.filter(el => el.Operation === 'AuctionBid');

  let correctPlaceAuctionBids = getCorrectAuctionBidList(placeAuctionBids);

  //Place Auction Bids
  correctPlaceAuctionBids.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Spend',
        'withdrawal',
        'withdraw',
        'payment',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet',
        `AuctionBid - Place Auction Bid`,
        `AuctionBid - TxID: ${el.TxID}`,
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  return objData;
};
