import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as lmTool from './LMTools.js';
import * as mdl from '../model.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates all LM Actions for CAKE-File
 * @param {Array} data
 * @returns returns the list of objs
 */
export const generateCakeLMActions = async function (data, tool) {
  let objData = [];

  //Get all CAKE actions with lm
  let addCakeLMCoinActions = lmTool.getAddCakeLMCoinActions(data);
  let addCakeLMTokenActions = lmTool.getAddCakeLMTokenActions(data);
  let remCakeLMCoinActions = lmTool.getRemCakeLMCoinActions(data);
  let remCakeLMTokenActions = lmTool.getRemCakeLMTokenActions(data);

  //Generate Liquidity Mining Actions

  //c.1.) Generate LM Actions for CAKE V1
  if (addCakeLMCoinActions.length > 0 && addCakeLMTokenActions.length === 0) {
    //ACHTUNG: Use-Token ist hier nicht möglich!
    const err = `Old Cake format: Use of token is not possible here!`;
    console.error(`${err} 💥💥💥`);
    throw err;
  }
  hlp.logconsole('Generating LM actions for CAKE V2');
  let isError = false;
  addCakeLMTokenActions.forEach(token => {
    const ergArr = addCakeLMCoinActions.filter(el => {
      if (el.RelatedReference === token.Reference) {
        return true;
      }
      return false;
    });
    const lmAmount = Math.abs(token.Amount / 2);
    const lmCur = token.Cryptocurrency;

    if (ergArr.length === 2) {
      const coin1Amount = Math.abs(ergArr[0].Amount);
      const coin2Amount = Math.abs(ergArr[1].Amount);
      const coin1Cur = ergArr[0].Cryptocurrency;
      const coin2Cur = ergArr[1].Cryptocurrency;
      const elDate = token.Date;

      if (tool === 'Cointracking') {
        //Coin1 --> Provide Liquidity (send)
        //Trade 1
        objData.push(
          Actions.buildEntryObj(
            'Provide Liquidity',
            '',
            '',
            '',
            '',
            '',
            coin1Amount,
            coin1Cur,
            '',
            '',
            'CAKE',
            'Add Liquidity',
            `Coin1 Provide Liquidity`,
            elDate,
            `${coin1Cur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        //Coin2 --> Provide Liquidity (send)
        objData.push(
          Actions.buildEntryObj(
            'Provide Liquidity',
            '',
            '',
            '',
            '',
            '',
            coin2Amount,
            coin2Cur,
            '',
            '',
            'CAKE',
            'Add Liquidity',
            `Coin2 Provide Liquidity`,
            elDate,
            `${coin2Cur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        //Token--> Receive LP Token (rec)
        objData.push(
          Actions.buildEntryObj(
            'Receive LP Token',
            '',
            '',
            '',
            lmAmount * 2,
            lmCur,
            '',
            '',
            '',
            '',
            'CAKE',
            'Receive LP Token',
            `Receive Liquidity Token`,
            elDate,
            `${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
      } else {
        let actionName = 'Add Liquidity';
        if (tool === 'Koinly') {
          actionName = 'Liquidity in';
        }
        objData.push(
          Actions.buildEntryObj(
            'Trade',
            'trade',
            'order',
            '',
            lmAmount,
            lmCur,
            coin1Amount,
            coin1Cur,
            '',
            '',
            'CAKE',
            actionName,
            'Trade 1',
            elDate,
            `1${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        objData.push(
          Actions.buildEntryObj(
            'Trade',
            'trade',
            'order',
            '',
            lmAmount,
            lmCur,
            coin2Amount,
            coin2Cur,
            '',
            '',
            'CAKE',
            actionName,
            'Trade 2',
            elDate,
            `2${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
      }
    } else {
      isError = true;
    }
  });
  /////////////////////////////////////////////////////////////////////////////////////
  //Remove
  remCakeLMTokenActions.forEach(token => {
    const ergArr = remCakeLMCoinActions.filter(
      el => el.RelatedReference === token.Reference
    );
    const lmAmount = Math.abs(token.Amount / 2);
    const lmCur = token.Cryptocurrency;
    if (ergArr.length === 2) {
      const coin1Amount = Math.abs(ergArr[0].Amount);
      const coin2Amount = Math.abs(ergArr[1].Amount);
      const coin1Cur = ergArr[0].Cryptocurrency;
      const coin2Cur = ergArr[1].Cryptocurrency;
      const elDate = token.Date;

      if (tool === 'Cointracking') {
        //Coin1 --> Provide Liquidity (send)
        //Trade 1
        objData.push(
          Actions.buildEntryObj(
            'Remove Liquidity',
            '',
            '',
            '',
            coin1Amount,
            coin1Cur,
            '',
            '',
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            `Coin1 Remove Liquidity`,
            elDate,
            `${coin1Cur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        //Coin2 --> Provide Liquidity (send)
        objData.push(
          Actions.buildEntryObj(
            'Remove Liquidity',
            '',
            '',
            '',
            coin2Amount,
            coin2Cur,
            '',
            '',
            '',
            '',
            'CAKE',
            'Remove Liquidity',
            `Coin2 Remove Liquidity`,
            elDate,
            `${coin2Cur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        //Token--> Receive LP Token (rec)
        objData.push(
          Actions.buildEntryObj(
            'Return LP Token',
            '',
            '',
            '',
            '',
            '',
            lmAmount * 2,
            lmCur,
            '',
            '',
            'CAKE',
            'Return LP Token',
            `Return Liquidity Token`,
            elDate,
            `${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
      } else {
        let actionName = 'Remove Liquidity';
        if (tool === 'Koinly') {
          actionName = 'Liquidity out';
        }
        //Trade zusammenstellen
        objData.push(
          Actions.buildEntryObj(
            'Trade',
            'trade',
            'order',
            '',
            coin1Amount,
            coin1Cur,
            lmAmount,
            lmCur,
            '',
            '',
            'CAKE',
            actionName,
            'Trade 1',
            elDate,
            `1${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
        objData.push(
          Actions.buildEntryObj(
            'Trade',
            'trade',
            'order',
            '',
            coin2Amount,
            coin2Cur,
            lmAmount,
            lmCur,
            '',
            '',
            'CAKE',
            actionName,
            'Trade 2',
            elDate,
            `2${lmCur}${dateHelper.getTxIdDateString(elDate)}`,
            ''
          )
        );
      }
    } else {
      isError = true;
    }
  });
  if (isError) {
    const strErr = `!!!WARNING!!!\nThere are errors in the CAKE Export for Liquidity Mining.\nThis Bug is already reported to CAKE (issue 16810).\n\nThe defective lines are skipped!`;
    alert(strErr);
  }

  return objData;
};
