import * as dateHelper from '../Helper/DateHelper.js';
import * as hlp from '../Helper/Helper.js';
import * as Actions from '../Actions/Actions.js';

const getTradeActionsArray = function (data) {
  let tradeActionsArray = [];
  let swapArr = data.filter(el => el.Operation === 'PoolSwap');
  swapArr = hlp.removeDublicates(swapArr);

  //Set erstellen mit allen BlockHeights
  const blockHeights = new Set();
  swapArr.forEach(el => {
    blockHeights.add(el.Block);
  });
  //console.log(blockHeights);
  //Array erstellen mit allen Einträgen die zu dem Datum gehören
  blockHeights.forEach(el => {
    let entryArr = swapArr.filter(entry => entry.Block === el);
    //console.log(entryArr);
    if (entryArr.length === 2) {
      tradeActionsArray.push(entryArr);
    } else if (entryArr.length > 2) {
      const randVals = new Set();
      let bPoolTx = true;
      if (entryArr[0].PoolTx === undefined) {
        entryArr.forEach(el => {
          randVals.add(el.TxID);
        });
        bPoolTx = false;
      } else {
        entryArr.forEach(el => {
          randVals.add(el.PoolTx);
        });
      }

      randVals.forEach(el => {
        let entries;
        if (bPoolTx === true) {
          entries = entryArr.filter(entry => entry.PoolTx === el);
        } else {
          entries = entryArr.filter(entry => entry.TxID === el);
        }

        if (entries.length === 2) {
          tradeActionsArray.push(entries);
        } else {
          console.log('ups s');
          console.log(entries);
        }
      });
    } else {
      //console.log(entryArr);
      alert(
        `Error in generating PoolSwap-Transaction in Block: ${el}! Please ensure, that all addresses are processed at the same time. Otherwise please contact Marcus!`
      );
      console.log(`Error in generating PoolSwap-Transaction. Block: ${el}!`);
    }
  });
  //console.log(tradeActionsArray);
  return tradeActionsArray;
};

/**
 * Generates the report data for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the list of objs
 */
export const generateDeFiTrades = function (data) {
  let objData = [];
  hlp.logconsole('Generating DeFiChain Trade Actions');

  let entriesArr = getTradeActionsArray(data);
  //Array erstellen mit allen Einträgen die zu dem Datum gehören
  entriesArr.forEach(entryArr => {
    //FIXME: Achtung: Hier fehlt noch die Fehlerbehandlung!!!
    if (entryArr.length === 2) {
      const date = entryArr[0].Date;
      let inAmount;
      let outAmount;
      let inCur;
      let outCur;
      if (entryArr[0].Amount < 0) {
        outAmount = Math.abs(entryArr[0].Amount).toFixed(14);
        outCur = entryArr[0].Cryptocurrency;
        inAmount = Math.abs(entryArr[1].Amount).toFixed(14);
        inCur = entryArr[1].Cryptocurrency;
      } else {
        inAmount = Math.abs(entryArr[0].Amount).toFixed(14);
        inCur = entryArr[0].Cryptocurrency;
        outAmount = Math.abs(entryArr[1].Amount).toFixed(14);
        outCur = entryArr[1].Cryptocurrency;
      }
      //Match found
      objData.push(
        Actions.buildEntryObj(
          'Trade',
          'trade',
          'order',
          '',
          inAmount,
          inCur,
          outAmount,
          outCur,
          '',
          '',
          'DeFiChain Wallet',
          'DEX Trade',
          'Poolswap',
          date,
          `${inCur}${outCur}${dateHelper.getTxIdDateString(date)}_TxID:${
            entryArr[0].TxID
          }`,
          ''
        )
      );
    } else {
      //Fehler in SWAP-Elementen
      console.log(`Error in PoolSwap-Transaction. Block: ${el}!`);
    }
  });
  return objData;
};
