import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * This function gets the grouped entry staking data
 * @param {Array} data - Complete data
 * @param {String} groupingType - type of grouping (month, day or none)
 * @returns the grouped entries on a groupingType base
 */
const getGroupedStakingCakeData = function (data, groupingType) {
  const retArr = [];
  const groupedData = [];
  groupedData.push(data.filter(el => el.Cryptocurrency === 'DFI'));
  groupedData.push(data.filter(el => el.Cryptocurrency === 'DASH'));
  groupedData.push(data.filter(el => el.Cryptocurrency === 'XZC'));

  if (groupingType === 'day') {
    groupedData.forEach(arr => {
      dateHelper.getListOfDays(arr).forEach(el => {
        let erg = dateHelper.getListForDays(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  if (groupingType === 'month') {
    groupedData.forEach(arr => {
      dateHelper.getListOfMonth(arr).forEach(el => {
        let erg = dateHelper.getListForMonth(arr, el);
        retArr.push(hlp.getGroupedEntry(erg, ''));
      });
    });
  }
  if (groupingType === 'none') {
    retArr.push(...data);
  }
  return retArr;
};

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateStakingCakeActions = function (data, grouping, tool) {
  let objData = [];
  hlp.logconsole('Generating Cake Staking Actions');

  const groupableEnterStakingActions = data.filter(
    el => el.Operation === 'Entry staking wallet'
  );

  const enterStakingActions = getGroupedStakingCakeData(
    groupableEnterStakingActions,
    grouping
  );

  //console.log(groupableEnterStakingActions);
  const enterLendingBonusStakingActions = data.filter(el => {
    if (
      el.Operation === 'Entry staking wallet: Lending DFI Bonus' ||
      el.Operation === 'Entry staking wallet: Confectionery Lending DFI Bonus'
    ) {
      return true;
    } else {
      return false;
    }
  });
  const enterGiftBonusStakingActions = data.filter(el => {
    if (
      el.Operation === 'Entry staking wallet: Signup bonus' ||
      el.Operation === 'Entry staking wallet: Referral signup bonus' ||
      el.Operation === 'Entry staking wallet: Freezer promotion bonus' ||
      el.Operation === 'Entry staking wallet: Promotion bonus'
    ) {
      return true;
    } else {
      return false;
    }
  });

  const enterAirdropStakingActions = data.filter(
    el => el.Operation === 'Entry staking wallet: Bonus/Airdrop'
  );

  const exitStakingActions = data.filter(
    el => el.Operation === 'Exit staking wallet'
  );

  //Direct enter to staking wallet actions from lending bonus
  enterLendingBonusStakingActions.forEach(el => {
    if (el.Cryptocurrency === 'XZC') {
      el.Cryptocurrency = 'FIRO';
    }
    objData.push(
      Actions.buildEntryObj(
        'Reward / Bonus',
        'lending',
        'deposit',
        'lending_income',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Staking',
        `${el.Operation} - Bonus`,
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
    if (tool === 'Koinly') {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE',
          'Send to Pool',
          `${el.Operation} - Send to Staking pool`,
          el.Date,
          `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    } else {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE - Staking',
          'Staking',
          `${el.Operation} - Deposit to Staking wallet`,
          el.Date,
          `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE',
          'Staking',
          'Entry Staking wallet - withdraw from normal wallet',
          el.Date,
          `3_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    }
  });
  //Direct enter to staking wallet actions from bonus
  enterGiftBonusStakingActions.forEach(el => {
    if (el.Cryptocurrency === 'XZC') {
      el.Cryptocurrency = 'FIRO';
    }
    objData.push(
      Actions.buildEntryObj(
        'Reward / Bonus',
        'bounty',
        'deposit',
        'gift_received',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Staking',
        `${el.Operation} - Bonus`,
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
    if (tool === 'Koinly') {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE',
          'Send to Pool',
          `${el.Operation} - Send to Staking pool`,
          el.Date,
          `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    } else {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE - Staking',
          'Staking',
          `${el.Operation} - Deposit to Staking wallet`,
          el.Date,
          `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE',
          'Staking',
          'Entry Staking wallet - withdraw from normal wallet',
          el.Date,
          `3_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    }
  });

  //Direct enter to staking wallet actions from airdrop
  enterAirdropStakingActions.forEach(el => {
    if (el.Cryptocurrency === 'XZC') {
      el.Cryptocurrency = 'FIRO';
    }
    objData.push(
      Actions.buildEntryObj(
        'Airdrop',
        'airdrop',
        'deposit',
        'airdrop',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Staking',
        `${el.Operation} - Airdrop`,
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
    if (tool === 'Koinly') {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE',
          'Send to Pool',
          `${el.Operation} - Send to Staking pool`,
          el.Date,
          `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    } else {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE - Staking',
          'Staking',
          `${el.Operation} - Deposit to Staking wallet`,
          el.Date,
          `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE',
          'Staking',
          'Entry Staking wallet - withdraw from normal wallet',
          el.Date,
          `3_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    }
  });

  //Deposit to Staking-Wallet-Actions
  enterStakingActions.forEach(el => {
    if (el.Cryptocurrency === 'XZC') {
      el.Cryptocurrency = 'FIRO';
    }
    if (tool === 'Koinly') {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE',
          'Send to Pool',
          `${el.Operation} - Send to Staking Pool`,
          el.Date,
          `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    } else {
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE - Staking',
          'Staking',
          `${el.Operation} - Deposit to Staking wallet`,
          el.Date,
          `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE',
          'Staking',
          'Entry Staking wallet - withdraw from normal wallet',
          el.Date,
          `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    }
  });
  //Withdraw to Staking-Wallet-Actions
  exitStakingActions.forEach(el => {
    if (el.Cryptocurrency === 'XZC') {
      el.Cryptocurrency = 'FIRO';
    }
    if (tool === 'Koinly') {
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE - Staking',
          'Received from Pool',
          'Exit Staking wallet - Received from staking pool',
          el.Date,
          `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    } else {
      objData.push(
        Actions.buildEntryObj(
          'Withdrawal',
          'withdrawal',
          'withdraw',
          '',
          '',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          'CAKE - Staking',
          'Staking',
          'Exit Staking wallet - withdraw from staking wallet',
          el.Date,
          `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
      objData.push(
        Actions.buildEntryObj(
          'Deposit',
          'deposit',
          'deposit',
          '',
          Math.abs(el.Amount).toFixed(14),
          el.Cryptocurrency,
          '',
          '',
          '',
          '',
          'CAKE',
          'Staking',
          `${el.Operation} - Deposit to normal wallet`,
          el.Date,
          `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
        )
      );
    }
  });

  //console.log(objData);
  return objData;
};
