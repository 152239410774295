export const INFOLOGGING = true;
export const DEBUGLOGGING = true;
export const CAKE_FILE = 1;
export const DEFIAPP_FILE = 2;
export const DEFIPORTFOLIO_FILE = 3;
export const DEFIAPP_FILE_231 = 4;
export const DEFIAPP_FILE_232 = 5;
export const CAKE_FILE_20 = 6;
export const DEFIPORTFOLIO_FILE_NEW = 7;

export const YEARLIST = [2020, 2021, 2022, 2023];

export const COINLIST = [
  'BTC',
  'ETH',
  'DFI',
  'DASH',
  'XZC',
  'PIVX',
  'USDT',
  'LTC',
  'DOGE',
  'BCH',
  'USDC',
  'EUROC',
  'SOL',
  'MATIC',
  'DOT',
  'SUI',
];

export const LPTOKEN = [
  'BTC-DFI',
  'ETH-DFI',
  'LTC-DFI',
  'BCH-DFI',
  'DOGE-DFI',
  'USDT-DFI',
  'USDC-DFI',
  'DUSD-DFI',
  'SOL-DFI',
  'DOT-DFI',
  'MATIC-DFI',
  'SUI-DFI',
  'TSLA-DUSD',
  'QQQ-DUSD',
  'SPY-DUSD',
  'SLV-DUSD',
  'AAPL-DUSD',
  'GLD-DUSD',
  'GME-DUSD',
  'GOOGL-DUSD',
  'ARKK-DUSD',
  'BABA-DUSD',
  'VNQ-DUSD',
  'URTH-DUSD',
  'TLT-DUSD',
  'PDBC-DUSD',
  'EEM-DUSD',
  'AMZN-DUSD',
  'NVDA-DUSD',
  'COIN-DUSD',
  'MSFT-DUSD',
  'FB-DUSD',
  'NFLX-DUSD',
  'VOO-DUSD',
  'DIS-DUSD',
  'MCHI-DUSD',
  'MSTR-DUSD',
  'INTC-DUSD',
  'PYPL-DUSD',
  'BRK.B-DUSD',
  'KO-DUSD',
  'PG-DUSD',
  'SAP-DUSD',
  'CS-DUSD',
  'GSG-DUSD',
  'URA-DUSD',
  'XOM-DUSD',
  'GOVT-DUSD',
  'TAN-DUSD',
  'PPLT-DUSD',
  'DAX-DUSD',
  'JNJ-DUSD',
  'ADDYY-DUSD',
  'GS-DUSD',
  'USDT-DUSD',
  'USDC-DUSD',
  'UNG-DUSD',
  'USO-DUSD',
  'WMT-DUSD',
  'UL-DUSD',
  'ARKX-DUSD',
  'XLRE-DUSD',
  'VBK-DUSD',
  'XLE-DUSD',
  'NSRGY-DUSD',
  'SHEL-DUSD',
  'SH-DUSD',
  'BITI-DUSD',
  'EUROC-DUSD',
  'EUROC-DFI',
  'csETH-ETH',
  //ADD_NEW_DTOKEN
];
export const STOCKTOKEN = [
  'DUSD',
  'TSLA',
  'GME',
  'GOOGL',
  'BABA',
  'PLTR',
  'AAPL',
  'SPY',
  'QQQ',
  'PDBC',
  'VNQ',
  'ARKK',
  'GLD',
  'URTH',
  'TLT',
  'SLV',
  'AMZN',
  'NVDA',
  'EEM',
  'COIN',
  'FB',
  'MSFT',
  'NFLX',
  'VOO',
  'DIS',
  'MCHI',
  'MSTR',
  'INTC',
  'BRK.B',
  'KO',
  'PG',
  'PYPL',
  'CS',
  'SAP',
  'GSG',
  'URA',
  'XOM',
  'GOVT',
  'TAN',
  'PPLT',
  'DAX',
  'JNJ',
  'ADDYY',
  'GS',
  'UNG',
  'USO',
  'WMT',
  'UL',
  'ARKX',
  'XLRE',
  'VBK',
  'XLE',
  'NSRGY',
  'SHEL',
  'SH',
  'BITI',
  //ADD_NEW_DTOKEN
];

//Other options --> automatic (if address mode: DeFiChain Light Wallet)
export const DEPOT_LABEL = new Map([
  ['defichain', 'DeFiChain Wallet'],
  ['defichainlw', 'DeFiChain Light Wallet'],
  ['cake', 'CAKE'],
]);

export const poolIdMap = new Map([
  ['ETH-DFI', '4'],
  ['BTC-DFI', '5'],
  ['USDT-DFI', '6'],
  ['DOGE-DFI', '8'],
  ['LTC-DFI', '10'],
  ['BCH-DFI', '12'],
  ['USDC-DFI', '14'],
  ['DUSD-DFI', '17'],
  ['TSLA-DUSD/v1', '18'],
  ['TSLA-DUSD', '114'],
  ['GME-DUSD/v1', '25'],
  ['GME-DUSD', '104'],
  ['GOOGL-DUSD/v1', '32'],
  ['GOOGL-DUSD', '100'],
  ['BABA-DUSD', '33'],
  ['PLTR-DUSD', '35'],
  ['AAPL-DUSD', '36'],
  ['SPY-DUSD', '38'],
  ['QQQ-DUSD', '39'],
  ['PDBC-DUSD', '40'],
  ['VNQ-DUSD', '41'],
  ['ARKK-DUSD', '42'],
  ['GLD-DUSD', '43'],
  ['URTH-DUSD', '44'],
  ['TLT-DUSD', '45'],
  ['SLV-DUSD', '46'],
  ['AMZN-DUSD', '90'],
  ['NVDA-DUSD', '55'],
  ['EEM-DUSD', '53'],
  ['COIN-DUSD', '56'],
  ['MSFT-DUSD', '61'],
  ['NFLX-DUSD', '62'],
  ['VOO-DUSD', '63'],
  ['FB-DUSD', '64'],
  ['DIS-DUSD', '69'],
  ['MCHI-DUSD', '70'],
  ['MSTR-DUSD', '71'],
  ['INTC-DUSD', '72'],
  ['BRK.B-DUSD', '78'],
  ['KO-DUSD', '80'],
  ['PG-DUSD', '79'],
  ['PYPL-DUSD', '77'],
  ['CS-DUSD', '88'],
  ['SAP-DUSD', '85'],
  ['GSG-DUSD', '86'],
  ['URA-DUSD', '87'],
  ['XOM-DUSD', '97'],
  ['GOVT-DUSD', '98'],
  ['TAN-DUSD', '96'],
  ['PPLT-DUSD', '95'],
  ['DAX-DUSD', '109'],
  ['JNJ-DUSD', '110'],
  ['ADDYY-DUSD', '111'],
  ['GS-DUSD', '112'],
  ['USDT-DUSD', '101'],
  ['USDC-DUSD', '102'],
  ['UNG-DUSD', '121'],
  ['USO-DUSD', '122'],
  ['WMT-DUSD', '119'],
  ['UL-DUSD', '120'],
  ['ARKX-DUSD', '204'],
  ['XLRE-DUSD', '205'],
  ['VBK-DUSD', '206'],
  ['XLE-DUSD', '207'],
  ['NSRGY-DUSD', '212'],
  ['SHEL-DUSD', '213'],
  ['SH-DUSD', '214'],
  ['BITI-DUSD', '215'],
  ['EUROC-DFI', '217'],
  ['EUROC-DUSD', '218'],
  ['csETH-ETH', '125'],
  ['SOL-DFI', '229'],
  ['DOT-DFI', '230'],
  ['MATIC-DFI', '231'],
  ['SUI-DFI', '233'],
  ['XCHF-DFI', '235'],
  ['XCHF-DUSD', '236'],

  //ADD_NEW_DTOKEN
]);

export const FIAT_CURRENCY_MAP = new Map([
  ['eur', '€'],
  ['usd', '$'],
  ['chf', 'CHF'],
]);

export const COINTRACKING_TOKEN_MAP = new Map([
  ['EUR', 'EUR'],
  ['', ''],
  ['DFI', 'DFI'],
  ['DASH', 'DASH'],
  ['XZC', 'XZC'],
  ['FIRO', 'FIRO'],
  ['ETH', 'ETH'],
  ['BTC', 'BTC'],
  ['USDT', 'USDT'],
  ['DOGE', 'DOGE'],
  ['LTC', 'LTC'],
  ['BCH', 'BCH'],
  ['USDC', 'USDC'],
  ['DUSD', 'DUSD4'],
  ['MATIC', 'MATIC'],
  ['DOT', 'DOT'],
  ['SOL', 'SOL'],
  ['SUI', 'SUI'],
  ['TSLA', 'DTSLA'],
  ['GME', 'DGME'],
  ['GOOGL', 'DGOOGL'],
  ['BABA', 'DBABA'],
  ['PLTR', 'DPLTR'],
  ['AAPL', 'DAAPL'],
  ['SPY', 'DSPY'],
  ['QQQ', 'DQQQ'],
  ['PDBC', 'DPDBC'],
  ['VNQ', 'DVNQ'],
  ['ARKK', 'DARKK'],
  ['GLD', 'DGLD2'],
  ['URTH', 'DURTH'],
  ['TLT', 'DTLT'],
  ['SLV', 'DSLV'],
  ['AMZN', 'DAMZN'],
  ['NVDA', 'DNVDA'],
  ['EEM', 'DEEM'],
  ['COIN', 'DCOIN'],
  ['MSFT', 'DMSFT'],
  ['NFLX', 'DNFLX'],
  ['VOO', 'DVOO'],
  ['FB', 'DFB'],
  ['DIS', 'DDIS'],
  ['MCHI', 'DMCHI'],
  ['MSTR', 'DMSTR'],
  ['INTC', 'DINTC'],
  ['BRK.B', 'DBRK'],
  ['KO', 'DKO'],
  ['PG', 'DPG'],
  ['PYPL', 'DPYPL'],
  ['CS', 'DCS'],
  ['SAP', 'DSAP'],
  ['GSG', 'DGSG'],
  ['URA', 'DURA'],
  ['XOM', 'DXOM'],
  ['GOVT', 'DGOVT'],
  ['TAN', 'DTAN'],
  ['PPLT', 'DPPLT'],
  ['DAX', 'DDAX'],
  ['JNJ', 'DJNJ'],
  ['ADDYY', 'DADDYY'],
  ['GS', 'DGS'],
  ['UNG', 'DUNG'],
  ['USO', 'DUSO'],
  ['WMT', 'DWMT'],
  ['UL', 'DUL'],
  ['ARKX', 'DARKX'],
  ['XLRE', 'DXLRE'],
  ['VBK', 'DVBK'],
  ['XLE', 'DXLE'],
  ['NSRGY', 'DNSRGY'],
  ['SHEL', 'DSHEL'],
  ['SH', 'DSH'],
  ['BITI', 'DBITI'],
  ['EUROC', 'EUROC'],

  //ADD_NEW_DTOKEN
  ['ETH-DFI', 'ETH-DFI'],
  ['BTC-DFI', 'BTC-DFI'],
  ['USDT-DFI', 'USDT-DFI'],
  ['DOGE-DFI', 'DOGE-DFI'],
  ['LTC-DFI', 'LTC-DFI'],
  ['BCH-DFI', 'BCH-DFI'],
  ['USDC-DFI', 'USDC-DFI'],
  ['DUSD-DFI', 'DUSD-DFI'],
  ['TSLA-DUSD', 'LP-TSLA'],
  ['GME-DUSD', 'LP-DUSD'],
  ['GOOGL-DUSD', 'LP-GOOGL'],
  ['BABA-DUSD', 'LP-BABA'],
  ['PLTR-DUSD', 'LP-PLTR'],
  ['AAPL-DUSD', 'LP-AAPL'],
  ['SPY-DUSD', 'LP-SPY'],
  ['QQQ-DUSD', 'LP-QQQ'],
  ['PDBC-DUSD', 'LP-PDBC'],
  ['VNQ-DUSD', 'LP-VNQ'],
  ['ARKK-DUSD', 'LP-ARKK'],
  ['GLD-DUSD', 'LP-GLD'],
  ['URTH-DUSD', 'LP-URTH'],
  ['TLT-DUSD', 'LP-TLT'],
  ['SLV-DUSD', 'LP-SLV'],
  ['AMZN-DUSD', 'LP-AMZN'],
  ['NVDA-DUSD', 'LP-NVDA'],
  ['EEM-DUSD', 'LP-EEM'],
  ['COIN-DUSD', 'LP-COIN'],
  ['FB-DUSD', 'LP-FB'],
  ['MSFT-DUSD', 'LP-MSFT'],
  ['NFLX-DUSD', 'LP-NFLX'],
  ['VOO-DUSD', 'LP-VOO'],
  ['DIS-DUSD', 'LP-DIS'],
  ['MCHI-DUSD', 'LP-MCHI'],
  ['MSTR-DUSD', 'LP-MSTR'],
  ['INTC-DUSD', 'LP-INTC'],
  ['BRK.B-DUSD', 'LP-BRK'],
  ['KO-DUSD', 'LP-KO'],
  ['PG-DUSD', 'LP-PG'],
  ['PYPL-DUSD', 'LP-PYPL'],
  ['CS-DUSD', 'LP-CS'],
  ['SAP-DUSD', 'LP-SAP'],
  ['GSG-DUSD', 'LP-GSG'],
  ['URA-DUSD', 'LP-URA'],
  ['XOM-DUSD', 'LP-XOM'],
  ['GOVT-DUSD', 'LP-GOVT'],
  ['TAN-DUSD', 'LP-TAN'],
  ['PPLT-DUSD', 'LP-PPLT'],
  ['DAX-DUSD', 'LP-DAX'],
  ['JNJ-DUSD', 'LP-JNJ'],
  ['ADDYY-DUSD', 'LP-ADDYY'],
  ['GS-DUSD', 'LP-GS'],
  ['USDT-DUSD', 'LP-USDT'],
  ['USDC-DUSD', 'LP-USDC'],
  ['UNG-DUSD', 'LP-UNG'],
  ['USO-DUSD', 'LP-USO'],
  ['WMT-DUSD', 'LP-WMT'],
  ['UL-DUSD', 'LP-UL'],
  ['ARKX-DUSD', 'LP-ARKX'],
  ['XLRE-DUSD', 'LP-XLRE'],
  ['VBK-DUSD', 'LP-VBK'],
  ['XLE-DUSD', 'LP-XLE'],
  ['NSRGY-DUSD', 'LP-NRGY'],
  ['SHEL-DUSD', 'LP-SHEL'],
  ['SH-DUSD', 'LP-SH'],
  ['BITI-DUSD', 'LP-BITI'],
  ['EUROC-DFI', 'EURO-DFI'],
  ['EUROC-DUSD', 'LP-EUROC'],
  //ADD_NEW_DTOKEN
]);

export const KOINLY_TOKEN_MAP = new Map([
  ['EUR', 'EUR'],
  ['', ''],
  ['DFI', 'DFI'],
  ['DASH', 'DASH'],
  ['XZC', 'XZC'],
  ['FIRO', 'FIRO'],
  ['ETH', 'ETH'],
  ['BTC', 'BTC'],
  ['USDT', 'USDT'],
  ['DOGE', 'DOGE'],
  ['LTC', 'LTC'],
  ['BCH', 'BCH'],
  ['SOL', 'SOL'],
  ['DOT', 'DOT'],
  ['MATIC', 'MATIC'],
  ['SUI', 'SUI'],
  ['USDC', 'USDC'],
  ['DUSD', 'DUSD'],
  ['TSLA', 'DTSLA'],
  ['GME', 'DGME'],
  ['GOOGL', 'DGOOGL'],
  ['BABA', 'DBABA'],
  ['PLTR', 'DPLTR'],
  ['AAPL', 'DAAPL'],
  ['SPY', 'DSPY'],
  ['QQQ', 'DQQQ'],
  ['PDBC', 'DPDBC'],
  ['VNQ', 'DVNQ'],
  ['ARKK', 'DARKK'],
  ['GLD', 'DGLD2'],
  ['URTH', 'DURTH'],
  ['TLT', 'DTLT'],
  ['SLV', 'DSLV'],
  ['AMZN', 'DAMZN'],
  ['NVDA', 'DNVDA'],
  ['EEM', 'DEEM'],
  ['COIN', 'DCOIN'],
  ['MSFT', 'DMSFT'],
  ['NFLX', 'DNFLX'],
  ['VOO', 'DVOO'],
  ['FB', 'DFB'],
  ['DIS', 'DDIS'],
  ['MCHI', 'DMCHI'],
  ['MSTR', 'DMSTR'],
  ['INTC', 'DINTC'],
  ['BRK.B', 'DBRK.B'],
  ['KO', 'DKO'],
  ['PG', 'DPG'],
  ['PYPL', 'DPYPL'],
  ['CS', 'DCS'],
  ['SAP', 'DSAP'],
  ['GSG', 'DGSG'],
  ['URA', 'DURA'],
  ['XOM', 'DXOM'],
  ['GOVT', 'DGOVT'],
  ['TAN', 'DTAN'],
  ['PPLT', 'DPPLT'],
  ['DAX', 'DDAX'],
  ['JNJ', 'DJNJ'],
  ['ADDYY', 'DADDYY'],
  ['GS', 'DGS'],
  ['UNG', 'DUNG'],
  ['USO', 'DUSO'],
  ['WMT', 'DWMT'],
  ['UL', 'DUL'],
  ['ARKX', 'DARKX'],
  ['XLRE', 'DXLRE'],
  ['VBK', 'DVBK'],
  ['XLE', 'DXLE'],
  ['NSRGY', 'DNSRGY'],
  ['SHEL', 'DSHEL'],
  ['SH', 'DSH'],
  ['BITI', 'DBITI'],
  ['EUROC', 'EUROC'],

  //ADD_NEW_DTOKEN
  ['ETH-DFI', 'ETH-DFI'],
  ['BTC-DFI', 'BTC-DFI'],
  ['USDT-DFI', 'USDT-DFI'],
  ['DOGE-DFI', 'DOGE-DFI'],
  ['LTC-DFI', 'LTC-DFI'],
  ['BCH-DFI', 'BCH-DFI'],
  ['USDC-DFI', 'USDC-DFI'],
  ['DUSD-DFI', 'DUSD-DFI'],
  ['TSLA-DUSD', 'DTSLA-DUSD'],
  ['GME-DUSD', 'DDUSD-DUSD'],
  ['GOOGL-DUSD', 'DGOOGL-DUSD'],
  ['BABA-DUSD', 'DBABA-DUSD'],
  ['PLTR-DUSD', 'DPLTR-DUSD'],
  ['AAPL-DUSD', 'DAAPL-DUSD'],
  ['SPY-DUSD', 'DSPY-DUSD'],
  ['QQQ-DUSD', 'DQQQ-DUSD'],
  ['PDBC-DUSD', 'DPDBC-DUSD'],
  ['VNQ-DUSD', 'DVNQ-DUSD'],
  ['ARKK-DUSD', 'DARKK-DUSD'],
  ['GLD-DUSD', 'DGLD-DUSD'],
  ['URTH-DUSD', 'DURTH-DUSD'],
  ['TLT-DUSD', 'DTLT-DUSD'],
  ['SLV-DUSD', 'DSLV-DUSD'],
  ['AMZN-DUSD', 'DAMZN-DUSD'],
  ['NVDA-DUSD', 'DNVDA-DUSD'],
  ['EEM-DUSD', 'DEEM-DUSD'],
  ['COIN-DUSD', 'DCOIN-DUSD'],
  ['FB-DUSD', 'DFB-DUSD'],
  ['MSFT-DUSD', 'DMSFT-DUSD'],
  ['NFLX-DUSD', 'DNFLX-DUSD'],
  ['VOO-DUSD', 'DVOO-DUSD'],
  ['DIS-DUSD', 'DDIS-DUSD'],
  ['MCHI-DUSD', 'DMCHI-DUSD'],
  ['MSTR-DUSD', 'DMSTR-DUSD'],
  ['INTC-DUSD', 'DINTC-DUSD'],
  ['BRK.B-DUSD', 'DBRK.B-DUSD'],
  ['KO-DUSD', 'DKO-DUSD'],
  ['PG-DUSD', 'DPG-DUSD'],
  ['PYPL-DUSD', 'DPYPL-DUSD'],
  ['CS-DUSD', 'DCS-DUSD'],
  ['SAP-DUSD', 'DSAP-DUSD'],
  ['GSG-DUSD', 'DGSG-DUSD'],
  ['URA-DUSD', 'DURA-DUSD'],
  ['XOM-DUSD', 'DXOM-DUSD'],
  ['GOVT-DUSD', 'DGOVT-DUSD'],
  ['TAN-DUSD', 'DTAN-DUSD'],
  ['PPLT-DUSD', 'DPPLT-DUSD'],
  ['DAX-DUSD', 'DDAX-DUSD'],
  ['JNJ-DUSD', 'DJNJ-DUSD'],
  ['ADDYY-DUSD', 'DADDYY-DUSD'],
  ['GS-DUSD', 'DGS-DUSD'],
  ['USDT-DUSD', 'DUSDT-DUSD'],
  ['USDC-DUSD', 'DUSDC-DUSD'],
  ['UNG-DUSD', 'DUNG-DUSD'],
  ['USO-DUSD', 'DUSO-DUSD'],
  ['WMT-DUSD', 'DWMT-DUSD'],
  ['UL-DUSD', 'DUL-DUSD'],
  ['ARKX-DUSD', 'DARKX-DUSD'],
  ['XLRE-DUSD', 'DXLRE-DUSD'],
  ['VBK-DUSD', 'DVBK-DUSD'],
  ['XLE-DUSD', 'DXLE-DUSD'],
  ['NSRGY-DUSD', 'DNRGY-DUSD'],
  ['SHEL-DUSD', 'DSHEL-DUSD'],
  ['SH-DUSD', 'DSH-DUSD'],
  ['BITI-DUSD', 'DBITI-DUSD'],
  ['EUROC-DUSD', 'EUROC-DUSD'],
  ['EUROC-DFI', 'EUROC-DFI'],
  //ADD_NEW_DTOKEN
]);

export const BLOCKPIT_TOKEN_MAP = new Map([
  ['EUR', 'EUR'],
  ['', ''],
  ['DFI', 'DFI'],
  ['DASH', 'DASH'],
  ['XZC', 'XZC'],
  ['FIRO', 'FIRO'],
  ['ETH', 'ETH'],
  ['BTC', 'BTC'],
  ['USDT', 'USDT'],
  ['DOGE', 'DOGE'],
  ['LTC', 'LTC'],
  ['BCH', 'BCH'],
  ['USDC', 'USDC'],
  ['DUSD', 'DUSD'],
  ['TSLA', 'DTSLA'],
  ['GME', 'DGME'],
  ['GOOGL', 'DGOOGL'],
  ['BABA', 'DBABA'],
  ['PLTR', 'DPLTR'],
  ['AAPL', 'DAAPL'],
  ['SPY', 'DSPY'],
  ['QQQ', 'DQQQ'],
  ['PDBC', 'DPDBC'],
  ['VNQ', 'DVNQ'],
  ['ARKK', 'DARKK'],
  ['GLD', 'DGLD2'],
  ['URTH', 'DURTH'],
  ['TLT', 'DTLT'],
  ['SLV', 'DSLV'],
  ['AMZN', 'DAMZN'],
  ['NVDA', 'DNVDA'],
  ['EEM', 'DEEM'],
  ['COIN', 'DCOIN'],
  ['MSFT', 'DMSFT'],
  ['NFLX', 'DNFLX'],
  ['VOO', 'DVOO'],
  ['FB', 'DFB'],
  ['DIS', 'DDIS'],
  ['MCHI', 'DMCHI'],
  ['MSTR', 'DMSTR'],
  ['INTC', 'DINTC'],
  ['BRK.B', 'DBRK.B'],
  ['KO', 'DKO'],
  ['PG', 'DPG'],
  ['PYPL', 'DPYPL'],
  ['CS', 'DCS'],
  ['SAP', 'DSAP'],
  ['GSG', 'DGSG'],
  ['URA', 'DURA'],
  ['XOM', 'DXOM'],
  ['GOVT', 'DGOVT'],
  ['TAN', 'DTAN'],
  ['PPLT', 'DPPLT'],
  ['DAX', 'DDAX'],
  ['JNJ', 'DJNJ'],
  ['ADDYY', 'DADDYY'],
  ['GS', 'DGS'],
  ['UNG', 'DUNG'],
  ['USO', 'DUSO'],
  ['WMT', 'DWMT'],
  ['UL', 'DUL'],
  ['ARKX', 'DARKX'],
  ['XLRE', 'DXLRE'],
  ['VBK', 'DVBK'],
  ['XLE', 'DXLE'],
  ['NSRGY', 'DNSRGY'],
  ['SHEL', 'DSHEL'],
  ['SH', 'DSH'],
  ['BITI', 'DBITI'],
  ['EUROC', 'EUROC'],

  //ADD_NEW_DTOKEN
  ['ETH-DFI', 'ETH/DFI'],
  ['BTC-DFI', 'BTC/DFI'],
  ['USDT-DFI', 'USDT/DFI'],
  ['DOGE-DFI', 'DOGE/DFI'],
  ['LTC-DFI', 'LTC/DFI'],
  ['BCH-DFI', 'BCH/DFI'],
  ['USDC-DFI', 'USDC/DFI'],
  ['DUSD-DFI', 'DUSD/DFI'],
  ['TSLA-DUSD', 'DTSLA/DUSD'],
  ['GME-DUSD', 'DDUSD/DUSD'],
  ['GOOGL-DUSD', 'DGOOGL/DUSD'],
  ['BABA-DUSD', 'DBABA/DUSD'],
  ['PLTR-DUSD', 'DPLTR/DUSD'],
  ['AAPL-DUSD', 'DAAPL/DUSD'],
  ['SPY-DUSD', 'DSPY/DUSD'],
  ['QQQ-DUSD', 'DQQQ/DUSD'],
  ['PDBC-DUSD', 'DPDBC/DUSD'],
  ['VNQ-DUSD', 'DVNQ/DUSD'],
  ['ARKK-DUSD', 'DARKK/DUSD'],
  ['GLD-DUSD', 'DGLD/DUSD'],
  ['URTH-DUSD', 'DURTH/DUSD'],
  ['TLT-DUSD', 'DTLT/DUSD'],
  ['SLV-DUSD', 'DSLV/DUSD'],
  ['AMZN-DUSD', 'DAMZN/DUSD'],
  ['NVDA-DUSD', 'DNVDA/DUSD'],
  ['EEM-DUSD', 'DEEM/DUSD'],
  ['COIN-DUSD', 'DCOIN/DUSD'],
  ['FB-DUSD', 'DFB/DUSD'],
  ['MSFT-DUSD', 'DMSFT/DUSD'],
  ['NFLX-DUSD', 'DNFLX/DUSD'],
  ['VOO-DUSD', 'DVOO/DUSD'],
  ['DIS-DUSD', 'DDIS/DUSD'],
  ['MCHI-DUSD', 'DMCHI/DUSD'],
  ['MSTR-DUSD', 'DMSTR/DUSD'],
  ['INTC-DUSD', 'DINTC/DUSD'],
  ['BRK.B-DUSD', 'DBRK.B/DUSD'],
  ['KO-DUSD', 'DKO/DUSD'],
  ['PG-DUSD', 'DPG/DUSD'],
  ['PYPL-DUSD', 'DPYPL/DUSD'],
  ['CS-DUSD', 'DCS/DUSD'],
  ['SAP-DUSD', 'DSAP/DUSD'],
  ['GSG-DUSD', 'DGSG/DUSD'],
  ['URA-DUSD', 'DURA/DUSD'],
  ['XOM-DUSD', 'DXOM/DUSD'],
  ['GOVT-DUSD', 'DGOVT/DUSD'],
  ['TAN-DUSD', 'DTAN/DUSD'],
  ['PPLT-DUSD', 'DPPLT/DUSD'],
  ['DAX-DUSD', 'DDAX/DUSD'],
  ['JNJ-DUSD', 'DJNJ/DUSD'],
  ['ADDYY-DUSD', 'DADDYY/DUSD'],
  ['GS-DUSD', 'DGS/DUSD'],
  ['USDT-DUSD', 'DUSDT/DUSD'],
  ['USDC-DUSD', 'DUSDC/DUSD'],
  ['UNG-DUSD', 'DUNG/DUSD'],
  ['USO-DUSD', 'DUSO/DUSD'],
  ['WMT-DUSD', 'DWMT/DUSD'],
  ['UL-DUSD', 'DUL/DUSD'],
  ['ARKX-DUSD', 'DARKX/DUSD'],
  ['XLRE-DUSD', 'DXLRE/DUSD'],
  ['VBK-DUSD', 'DVBK/DUSD'],
  ['XLE-DUSD', 'DXLE/DUSD'],
  ['NSRGY-DUSD', 'DNRGY/DUSD'],
  ['SHEL-DUSD', 'DSHEL/DUSD'],
  ['SH-DUSD', 'DSH/DUSD'],
  ['BITI-DUSD', 'DBITI/DUSD'],
  ['EUROC-DUSD', 'EUROC/DUSD'],
  ['EUROC-DFI', 'EUROC/DFI'],
  //ADD_NEW_DTOKEN
]);

export const CSVHEADER = new Map([
  [
    'Cointracking',
    `Type,Buy Amount,Buy Cur.,Sell Amount,Sell Cur.,Fee Amount (optional),Fee Cur. (optional),Exchange (optional),Trade Group (optional),Comment (optional),Date,Tx-ID\n`,
  ],
  /*[
    'Blockpit',
    `;;;;;;;;;Version: 1.0\n;;;;;;;;;\nTransaction Type;From quantity;From currency;To quantity;To currency;Fees paid;Fee currency;Timestamp;Acquisition Timestamp;Note\n`,
  ],*/
  [
    'Blockpit',
    'id,exchange_name,depot_name,transaction_date,buy_asset,buy_amount,sell_asset,sell_amount,fee_asset,fee_amount,transaction_type,note,linked_transaction\n',
  ],
  [
    'Koinly',
    `Date,Sent Amount,Sent Currency,Received Amount,Received Currency,Fee Amount,Fee Currency,Net Worth Amount,Net Worth Currency,Label,Description,TxHash\n`,
  ],
]);

export const GROUPABLE_ACTIONS = [
  'Staking reward',
  'Liquidity mining reward',
  'Rewards',
  'Commission',
  'Coinbase',
  'Freezer staking bonus',
  'Freezer liquidity mining bonus',
  '10 years freezer reward',
  '5 years freezer reward',
  'Earn reward',
  'YieldVault reward',
  'Received ELITE reward booster from Staking',
  'ELITE 10 years freezer reward booster',
  'ELITE 5 years freezer reward booster',
  'Received ELITE Earn reward booster',
  'Received ELITE liquidity mining reward booster',
];
export const SPECIAL_ACTIONS = ['Entry staking wallet'];
export const NON_GROUPABLE_ACTIONS = [
  'Lapis DFI Bonus',
  'Lending DFI Bonus',
  'Entry staking wallet: Lending DFI Bonus',
  'Lapis reward',
  'Lending reward',
  'Deposit',
  'Swapped in',
  'Swapped out',
  'Add liquidity',
  'Withdrawal',
  'Bonus/Airdrop',
  'Entry staking wallet: Bonus/Airdrop',
  'Withdrawal fee',
  'Unstake fee',
  'Exit staking wallet fee',
  'Signup bonus',
  'Entry staking wallet: Signup bonus',
  'Remove liquidity',
  'Remove liquidity fee',
  'Referral signup bonus',
  'Entry staking wallet: Referral signup bonus',
  'Confectionery Lapis DFI Bonus',
  'Confectionery Lending DFI Bonus',
  'Entry staking wallet: Confectionery Lending DFI Bonus',
  'Referral reward',
  'Added liquidity',
  'Removed liquidity',
  'Claimed for 50% discount',
  'Used for 50% discount',
  'Paid deposit fee',
  'receive',
  'PoolSwap',
  'AddPoolLiquidity',
  'RemovePoolLiquidity',
  'UtxosToAccount',
  'AccountToAccount',
  'AnyAccountsToAccounts',
  'AccountToUtxos',
  'sent',
  'blockReward',
  '',
  'Custom transaction by the Cake team',
  'Paid swap fee',
  'Withdrew for swap',
  'Freezer promotion bonus',
  'Entry staking wallet: Freezer promotion bonus',
  'Address creation fee',
  'Entry staking wallet: Promotion bonus',
  'Promotion bonus',
  'Fee reimbursed',
  'DepositToVault',
  'TakeLoan',
  'PaybackLoan',
  'WithdrawFromVault',
  'CloseVault',
  'AuctionBid',
  'Exit staking wallet',
  'Remove collateral',
  'Add collateral',
  'Repay Borrowed Assets',
  'Pay borrowing fees',
  'Borrow asset',
  'TokenSplit',
  'Entered Earn',
  'Exited Earn',
  'Adjusted Earn entry',
  'Entered YieldVault',
  'Exited YieldVault',
  'Adjusted YieldVault entry',
  'DFIP2203',
  'FutureSwapExecution',
  'Token split ingoing',
  'Token split outgoing',
  'ELITE yearly',
  'ELITE monthly',
  'DCA referral rewards',
  'Deposit recurring buy',
];

export const CAKE_LM_ADD_COIN = ['Add liquidity'];

export const CAKE_LM_ADD_TOKEN = ['Added liquidity'];

export const CAKE_LM_REMOVE_COIN = ['Remove liquidity'];
export const CAKE_LM_REMOVE_TOKEN = ['Removed liquidity'];

export const DEFI_LM_ADD = ['AddPoolLiquidity'];
export const DEFI_LM_REMOVE = ['RemovePoolLiquidity'];

const objStaking = {
  cointrackingid: 'Staking',
  blockpitid: 'staking',
  accointingid: 'deposit',
  accointing_class: 'staked',
  inout: false,
  deposit: true,
  fee: false,
  exchange: 'CAKE',
};

const objCakeMining = {
  cointrackingid: 'LP Rewards',
  blockpitid: 'mining',
  accointing_class: 'income',
  accointingid: 'deposit',
  inout: false,
  deposit: true,
  fee: false,
  exchange: 'CAKE',
};

const objLendingBonus = {
  cointrackingid: 'Reward / Bonus',
  blockpitid: 'lending',
  accointing_class: 'lending_income',
  accointingid: 'deposit',
  inout: false,
  deposit: true,
  fee: false,
  exchange: 'CAKE',
};

const objGiftBonus = {
  cointrackingid: 'Reward / Bonus',
  blockpitid: 'bounty',
  accointing_class: 'gift_received',
  accointingid: 'deposit',
  inout: false,
  deposit: true,
  fee: false,
  exchange: 'CAKE',
};

const objLendingIncome = {
  cointrackingid: 'Lending Income',
  blockpitid: 'lending',
  accointing_class: 'lending_income',
  accointingid: 'deposit',
  inout: false,
  deposit: true,
  fee: false,
  exchange: 'CAKE',
};

const objCakeFee = {
  cointrackingid: 'Other Fee',
  blockpitid: 'gift',
  accointing_class: 'fee',
  accointingid: 'withdraw',
  inout: false,
  deposit: false,
  fee: true,
  exchange: 'CAKE',
};

export const CSVMAPPING = new Map([
  ['Staking reward', objStaking],
  ['Freezer staking bonus', objStaking],
  ['10 years freezer reward', objStaking],
  ['Received ELITE reward booster from Staking', objStaking],
  ['ELITE 10 years freezer reward booster', objStaking],
  ['ELITE 5 years freezer reward booster', objStaking],
  ['5 years freezer reward', objStaking],
  ['Freezer liquidity mining bonus', objCakeMining],
  ['', objGiftBonus],
  ['Liquidity mining reward', objCakeMining],
  ['Received ELITE liquidity mining reward booster', objCakeMining],
  ['Earn reward', objCakeMining],
  ['Received ELITE Earn reward booster', objCakeMining],
  ['ELITE yearly', objCakeFee],
  ['ELITE monthly', objCakeFee],
  //ADD_NEW_DTOKEN
  [
    'Bonus/Airdrop',
    {
      cointrackingid: 'Airdrop',
      blockpitid: 'airdrop',
      accointing_class: 'airdrop',
      accointingid: 'deposit',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Deposit',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  ['Lapis reward', objLendingIncome],
  ['Lending reward', objLendingIncome],
  ['Lapis DFI Bonus', objLendingBonus],
  ['Lending DFI Bonus', objLendingBonus],
  [
    'Withdrawal',
    {
      cointrackingid: 'Withdrawal',
      blockpitid: 'withdrawal',
      accointingid: 'withdraw',
      accointing_class: '',
      inout: false,
      deposit: false,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  ['Withdrawal fee', objCakeFee],
  ['Unstake fee', objCakeFee],
  ['Exit staking wallet fee', objCakeFee],
  ['Remove liquidity fee', objCakeFee],
  //ADD_NEW_DTOKEN
  ['Address creation fee', objCakeFee],
  ['Signup bonus', objGiftBonus],
  ['Referral signup bonus', objGiftBonus],
  ['Promotion bonus', objGiftBonus],
  ['Confectionery Lapis DFI Bonus', objLendingBonus],
  ['Confectionery Lending DFI Bonus', objLendingBonus],
  ['Referral reward', objGiftBonus],
  ['Paid deposit fee', objCakeFee],

  [
    'Claimed for 50% discount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Used for 50% discount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  [
    'Custom transaction by the Cake team',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  //['Unknown', objGiftBonus],
  ['Freezer promotion bonus', objGiftBonus],
  [
    //TODO: Is this correct?
    'Fee reimbursed',
    objGiftBonus,
  ],
  [
    'AccountToAccount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'AnyAccountsToAccounts',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: true,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Commission',
    {
      cointrackingid: 'LP Rewards',
      blockpitid: 'mining',
      accointingid: 'deposit',
      accointing_class: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Rewards',
    {
      cointrackingid: 'LP Rewards',
      blockpitid: 'mining',
      accointingid: 'deposit',
      accointing_class: 'income',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Coinbase',
    {
      cointrackingid: 'Staking',
      blockpitid: 'masternode',
      accointingid: 'deposit',
      accointing_class: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'receive',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'sent',
    {
      cointrackingid: 'Withdrawal',
      blockpitid: 'withdrawal',
      accointingid: 'withdraw',
      accointing_class: '',
      inout: false,
      deposit: false,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'blockReward',
    {
      cointrackingid: 'Staking',
      blockpitid: 'masternode',
      accointingid: 'deposit',
      accointing_class: 'staked',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'UtxosToAccount',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'AccountToUtxos',
    {
      cointrackingid: 'Withdrawal',
      blockpitid: 'withdrawal',
      accointingid: 'withdraw',
      accointing_class: '',
      inout: false,
      deposit: false,
      fee: false,
      exchange: 'DeFiChain Wallet',
    },
  ],
  [
    'Deposit recurring buy',
    {
      cointrackingid: 'Deposit',
      blockpitid: 'deposit',
      accointingid: 'deposit',
      accointing_class: '',
      inout: false,
      deposit: true,
      fee: false,
      exchange: 'CAKE',
    },
  ],
  ['DCA referral rewards', objGiftBonus],
]);
