import * as cfg from '../Helper/config.js';
import * as ocean from '../../API/OceanAPI.js';
import * as dateHelper from '../Helper/DateHelper.js';
let cachedPoolPairs = [];
let cachedLastPoolpairApiCall = 0;

/**
 * Gets the price of the given coin in currency from the oracle
 * @param {String} coin - Coin for which the price should be retrieved
 * @param {String} currency - Fiat curreny
 * @returns the Fiat value of the given coin
 */
export const getCoinPrice = async function (coin, currency) {
  try {
    let priceCoin;
    if (coin === 'DUSD-USD') {
      priceCoin = 1;
    } else {
      priceCoin = await ocean.getTokenPrice(`${coin}`);
    }

    if (currency === 'eur') {
      const eurusd = await ocean.getTokenPrice('EUR-USD');
      priceCoin = priceCoin / eurusd;
    }

    return priceCoin;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Calculates the given USD Value to given currency
 * @param {Number} value - Price to be converted
 * @param {String} currency - The target currency
 * @returns
 */
export const calcValueToCurrency = async function (value, currency) {
  try {
    let priceCoin;
    if (currency === 'eur') {
      const eurusd = await ocean.getTokenPrice('EUR-USD');
      priceCoin = value / eurusd;
    } else {
      priceCoin = value;
    }
    return priceCoin;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Calculates the price of the liquidity token in currency
 * @param {String} token - Token to be calculated
 * @param {String} currency - Fiat currency (eur or usd)
 * @returns the Fiat price for the given token
 */
export const getLMTokenPrice = async function (token, currency) {
  try {
    const dateCheck = Date.now();
    //Get PoolPair-Infos
    let poolPairs;
    if (dateCheck - cachedLastPoolpairApiCall < 120000) {
      poolPairs = cachedPoolPairs;
    } else {
      poolPairs = await ocean.getPoolPairInfos();
      cachedPoolPairs = poolPairs;
      cachedLastPoolpairApiCall = dateCheck;
    }
    const poolId = cfg.poolIdMap.get(token);
    const elem = poolPairs.find(el => el.id === poolId);

    let tokenPrice = elem.totalLiquidity.usd / elem.totalLiquidity.token;
    //console.log(tokenPrice);
    tokenPrice = calcValueToCurrency(tokenPrice, currency);

    return tokenPrice;
  } catch (err) {
    console.error(err);
    return NaN;
  }
};

/**
 * Gets the prices for the liquidity token
 * @param {String} cur -  Fiat currency for getting the price feed
 * @returns
 */
export const getLMPriceFeed = async function (cur) {
  const priceList = [];
  for (let i = 0; i < cfg.LPTOKEN.length; i++) {
    let entry = {};
    entry.ticker = cfg.LPTOKEN[i];
    entry.price = await getLMTokenPrice(entry.ticker, cur);
    priceList.push(entry);
  }
  return priceList;
};
