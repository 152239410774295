import View from './View.js';
import * as domElems from './DOMElements.js';
import * as cfg from '../model/helper/config.js';

class SummaryView extends View {
  _parentElement = domElems.areaOverview;
  _errorMessage = '';
  _message = '';

  clearView() {
    this._clear();
  }
  renderResult(data, currency) {
    let overAll = 0;
    let htmlContent = '';
    let monthlySum = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //data --> Enthält jeweils ein Array pro Coin
    data.forEach(coinArr => {
      //coinArr --> Enthält jeweils ein Array pro Einkommensart
      coinArr.forEach(incomeArr => {
        //incomeArr --> Aggregierte Einträge pro Monat
        let isEmpty = true;
        let opId = '';
        let coin = '';
        incomeArr.forEach(test => {
          if (test.Operation) {
            opId = test.Operation;
            coin = test.Cryptocurrency;
            isEmpty = false;
          }
        });
        if (!isEmpty) {
          htmlContent += `
        <tr>
        <th>${opId}-${coin}</th>`;
          incomeArr.forEach((el, i) => {
            let val;
            let amnt;
            if (el.Value) {
              val = `${el.Value.toFixed(2)} ${cfg.FIAT_CURRENCY_MAP.get(
                currency.toLowerCase()
              )}`;
              overAll += el.Value;
              monthlySum[i] += el.Value;
            } else {
              val = '';
            }
            if (el.Amount) {
              amnt = `${el.Amount.toFixed(4)} pcs.`;
            } else {
              amnt = '';
            }
            htmlContent += `
        <th colspan="1">${val}</th>
        <th colspan="1">${amnt}</th>
        `;
          });

          htmlContent += `</tr>`;
        }
      });
    });
    let htmlHeader = `
      <table class="js_table">
        <tbody>
          <tr class="js_table_header1">
            <th>Month</th>`;
    for (let i = 0; i < 12; i++) {
      htmlHeader += `<th colspan="2">${String(i + 1).padStart(2, '0')}</th>`;
    }
    htmlHeader += `
    </tr>
    <tr class="js_table_header2">
      <th>Values</th>`;
    for (let i = 0; i < 12; i++) {
      htmlHeader += `<th colspan="1">${cfg.FIAT_CURRENCY_MAP.get(
        currency.toLowerCase()
      )}</th>
      <th colspan="1">pcs.</th>`;
    }
    htmlHeader += `</tr>`;
    let htmlFooter = `</tbody>
  </table><br>
  <p>Overall Summary: ${overAll.toFixed(2)} ${cfg.FIAT_CURRENCY_MAP.get(
      currency.toLowerCase()
    )}</p>`;
    let htmlMonthlySummary = `<tr>
    <th>Summary:</th>`;
    monthlySum.forEach(el => {
      htmlMonthlySummary += `<th colspan="2">${el.toFixed(
        2
      )} ${cfg.FIAT_CURRENCY_MAP.get(currency.toLowerCase())}</th>`;
    });
    htmlMonthlySummary += `</tr>`;

    let html = htmlHeader + htmlContent + htmlMonthlySummary + htmlFooter;
    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', html);
  }
}

export default new SummaryView();
