//-----------------------------------------------------------------------------
// ALL DOM-ELEMENT-QUERIES
//-----------------------------------------------------------------------------
//Sections
export const sectionRewards = document.querySelector('#rewards');
export const sectionAboutMe = document.querySelector('#aboutme');
export const sectionDescription = document.querySelector('#description');
export const sectionConverter = document.querySelector('#fileconverter');

//Buttons
export const btnScrollToRewards = document.querySelector('.btn-rewards');
export const btnScrollToMore = document.querySelector('.btn-more');
export const fileElem = document.getElementById('file-item');
export const btnGenerateOverview = document.querySelector('.btn-overview');
export const btnDownloadReport = document.querySelector('.btn-download-ct');
export const btnSubmitAddresses = document.querySelector(
  '.btn-submit-addresses'
);

//Listboxes
export const selectOverviewYear = document.querySelector('.reward_select');
export const currencySelection = document.querySelector('.cur-sel');
export const toolSelected = document.querySelector('#which-report');
export const tokenHandling = document.querySelector('#use-token');
export const addressAreaAddresses = document.querySelector(
  '.address-area_addresses'
);

//Areas
export const areaOverview = document.querySelector('.coin--info--container');
export const spinnerArea = document.querySelector('.spinner-area');
export const dragZone = document.querySelector('.drop-area');
export const nav = document.querySelector('.nav');
export const header = document.querySelector('.header');
export const logger = document.querySelector('.logger');
export const slider = document.querySelector('.slider');
export const pricefeed = document.querySelector('.pricefeed');
export const dataArea = document.querySelector('.data-area');
export const addressArea = document.querySelector('.address-area');

//Toggle
export const toggleAdrSwitch = document.querySelector('.address_switch_state');
export const toggleAdrSwitchLabel = document.querySelector(
  '.address_switch_label'
);
