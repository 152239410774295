import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateEarnCakeActions = function (data, grouping) {
  let objData = [];
  hlp.logconsole('Generating Cake Earn Actions');

  const enterEarnActions = data.filter(el => el.Operation === 'Entered Earn');

  const exitEarnActions = data.filter(el => el.Operation === 'Exited Earn');

  const adjustEarnActions = data.filter(
    el => el.Operation === 'Adjusted Earn entry'
  );

  //Direct enter to staking wallet actions from lending bonus
  enterEarnActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE - Earn Wallet',
        'Earn',
        `${el.Operation} - Deposit to Earn wallet`,
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE',
        'Earn',
        'Entry Earn wallet - withdraw from normal wallet',
        el.Date,
        `3_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //Withdraw to Staking-Wallet-Actions
  exitEarnActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE - Earn Wallet',
        'Earn',
        'Exit Earn wallet - withdraw from earn wallet',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Earn',
        `${el.Operation} - Deposit to normal wallet`,
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  adjustEarnActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Adjust Earn entry',
        `${el.Operation} - Deposit to wallet`,
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //console.log(objData);
  return objData;
};
