import * as cfg from '../Model/Helper/config.js';
/**
 * Gets the rewards history for the given addresses from DFI.TAX
 * @param {Array} addressArray - Array with all addresses
 * @param {String} grouping - Grouping: 'day' or 'month'
 * @param {String} currency - FIAT currency 'eur' or 'usd'
 * @returns the reward data with the given parameters
 */
export const getDFITAXRewards = async function (
  addressArray,
  grouping,
  currency
) {
  try {
    const data = [];
    for (let i = 0; i < addressArray.length; i++) {
      const apiCall = `https://api.dfi.tax/p01/rwd/${
        addressArray[i]
      }/${grouping.toUpperCase()}/${currency.toUpperCase()}`;

      const resData = await fetch(apiCall);
      if (!resData.ok)
        throw new Error(`Problem getting DFI TAX data!\n ${resData.ok}`);
      data.push(...(await resData.json()));
    }
    //console.log(data);
    return data;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while fetching reward data from DFI.TAX - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * Gets the action history for the given addresses from DFI.TAX
 * @param {Array} addressArray - Array with all addresses
 * @param {String} year - The year to retrieve the history data
 * @param {String} currency - FIAT currency 'eur' or 'usd'
 * @returns the history data
 */
export const getDFITAXHistory = async function (addressArray, year, currency) {
  try {
    let strAddresses = '';
    const data = [];

    if (year === 'All') {
      for (let repYear of cfg.YEARLIST) {
        for (let i = 0; i < addressArray.length; i++) {
          const apiCall = `https://api.dfi.tax/p01/hst/${
            addressArray[i]
          }/${repYear}/${currency.toUpperCase()}`;
          const resData = await fetch(apiCall);
          if (!resData.ok)
            throw new Error(`Problem getting DFI.TAX data!\n ${resData.ok}`);
          data.push(...(await resData.json()));
        }
      }
    } else {
      for (let i = 0; i < addressArray.length; i++) {
        const apiCall = `https://api.dfi.tax/p01/hst/${
          addressArray[i]
        }/${year}/${currency.toUpperCase()}`;
        const resData = await fetch(apiCall);
        if (!resData.ok)
          throw new Error(`Problem getting DFI.TAX data!\n ${resData.ok}`);
        data.push(...(await resData.json()));
      }
    }

    //console.log(data);
    return data;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while fetching reward data from DFI.TAX - \n ${err.message}`
    );
    throw err;
  }
};

/**
 * Checks if an address is valid and adds it to the DFI.TAX infrastructure if it is not present
 * @param {String} address - A single address to be checkes / added
 * @returns true if address is valid and data is present. False otherwise
 */
export const addAndCheckAddressDFITAX = async function (address) {
  try {
    const apiCall = `https://api.dfi.tax/v01/chk/${address}`;
    const resData = await fetch(apiCall);
    if (!resData.ok)
      throw new Error(`Problem getting DFI.TAX data!\n ${resData.ok}`);
    const data = await resData.json();
    if (data.valid === false) {
      alert(`Address: ${address} is not valid!`);
    } else {
      if (data.present === false) {
        alert(`New Address, please wait a few minutes to get the data ready!`);
      }
    }
    let ergState = {};
    ergState.valid = data.valid;
    ergState.present = data.present;

    return ergState;
  } catch (err) {
    console.error(err);
    alert(
      `Something went wrong while adding a address to DFI.TAX - \n ${err.message}`
    );
    throw err;
  }
};
