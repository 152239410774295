import * as hlp from '../Helper/Helper.js';
import * as Actions from '../Actions/Actions.js';
import * as dateHelper from '../Helper/DateHelper.js';

/**
 * This function filters all withdrawal actions from the DFIP2203 list
 * @param {Array} rawDFIP2203List - List with all DFIP2203 actions (set and withdrawal)
 * @returns the list with DFIP2203 actions to be executed by FutureSwapExecution
 */
const getCorrectDFIP2203List = function (rawDFIP2203List) {
  let posList = [];
  let negList = [];
  rawDFIP2203List.forEach(el => {
    if (el.Amount < 0) {
      negList.push(el);
    } else {
      posList.push(el);
    }
  });
  posList.forEach(el => {
    let idx = negList.findIndex(
      el2 => Math.abs(el.Amount) === Math.abs(el2.Amount)
    );
    negList.splice(idx, 1);
  });
  return negList;
};

/**
 * This function generates all actions for the FutureSwaps
 * @param {Array} data - Data to be analysed
 * @returns returns the list of StockSplit obj
 */
export const generateFutureSwaps = function (data) {
  let objData = [];
  hlp.logconsole('Generating DeFiChain Future Swap Actions');

  //List with all FutureSwap entries
  let setFutureSwapActionsArray = data.filter(
    el => el.Operation === 'DFIP2203'
  );
  let execFutureSwapActionsArray = data.filter(
    el => el.Operation === 'FutureSwapExecution'
  );
  let dfip2203List = getCorrectDFIP2203List(setFutureSwapActionsArray);
  execFutureSwapActionsArray.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'DeFiChain Wallet',
        'FutureSwapExecution --> Manual work to do!',
        'FutureSwapExecution',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });
  dfip2203List.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'DeFiChain Wallet',
        'FutureSwapExecution --> Manual work to do!',
        'FutureSwapExecution',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  return objData;
};
