import * as DateHelper from '../Helper/DateHelper.js';

/**
 * Boils down an given array to a single object
 * @param {Array} groupData - Array with elements to be grouped
 */
const getGroupedData = function (groupData, newOpId) {
  let amountSum = 0;
  let valueSum = 0;
  let valueSumAPI = 0;
  let opCode = newOpId;
  groupData.forEach(elem => {
    amountSum += elem.Amount;
    valueSum += elem['FiatValue'];
  });
  if (newOpId.length === 0) {
    opCode = groupData[0].Operation;
  }
  return {
    Date: groupData[0].Date,
    Month: groupData[0].Date.getMonth() + 1,
    Operation: opCode,
    Cryptocurrency: groupData[0].Cryptocurrency,
    Amount: amountSum,
    Value: valueSum,
    Currency: groupData[0]['FiatCurrency'],
  };
};

/**
 * Gets the data gouped by month
 * @param {Array} data
 * @param {String} opCodeVisual
 */
const getMonthlyGroupedData = function (data, opCodeVisual) {
  let groupArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  DateHelper.getListOfMonth(data).forEach(el => {
    let erg = DateHelper.getListForMonth(data, el);
    const entry = getGroupedData(erg, opCodeVisual);
    groupArr[entry.Month - 1] = entry;
  });

  return groupArr;
};

/**
 * Gets the array with the monthly grouped data
 * @param {Array} data - array with staking data
 * @param {Number} year - Year to be filtered
 * @param {Array} operations - OperationIds to be filtered
 * @param {Array} opCodeVisual - Operation to be shown
 */
const getSummaryForTransactions = function (
  data,
  year,
  operations,
  opCodeVisual
) {
  let newArr = [];
  operations.forEach(op => {
    const erg = data.filter(el => el.Operation === op);
    newArr = newArr.concat(erg);
  });

  const yearDate = DateHelper.getElementsByYear(newArr, year);
  const filteredArr = getMonthlyGroupedData(yearDate, opCodeVisual);

  return filteredArr;
};

/**
 * Produces an Array with sums of different operations
 * @param {Array} data - staking data array
 * @param {Number} year - Year to be filtered
 */
export const sumRewards = function (data, year) {
  //Folgende Transaktionstypen sollen aufgelistet werden
  const ergArr = [];
  //Staking Rewards
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Staking reward', 'blockReward', 'Coinbase'],
      'Staking Reward'
    )
  );
  //Liquidity Mining Rewards
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      [
        'Rewards',
        'Commission',
        'Liquidity mining reward ETH-DFI',
        'Liquidity mining reward BTC-DFI',
        'Liquidity mining reward USDT-DFI',
        'Liquidity mining reward LTC-DFI',
        'Liquidity mining reward DOGE-DFI',
        'Liquidity mining reward BCH-DFI',
        'Liquidity mining reward USDC-DFI',
        'Liquidity mining reward DUSD-DFI',
        'Liquidity mining reward dTSLA-DUSD',
        'Liquidity mining reward dGME-DUSD',
        'Liquidity mining reward dGOOGL-DUSD',
        'Liquidity mining reward dBABA-DUSD',
        'Liquidity mining reward dPLTR-DUSD',
        'Liquidity mining reward dAAPL-DUSD',
        'Liquidity mining reward dSPY-DUSD',
        'Liquidity mining reward dQQQ-DUSD',
        'Liquidity mining reward dPDBC-DUSD',
        'Liquidity mining reward dVNQ-DUSD',
        'Liquidity mining reward dARKK-DUSD',
        'Liquidity mining reward dGLD-DUSD',
        'Liquidity mining reward dURTH-DUSD',
        'Liquidity mining reward dTLT-DUSD',
        'Liquidity mining reward dSLV-DUSD',
        'Liquidity mining reward dAMZN-DUSD',
        'Liquidity mining reward dNVDA-DUSD',
        'Liquidity mining reward dEEM-DUSD',
        'Liquidity mining reward dCOIN-DUSD',
        'Liquidity mining reward dFB-DUSD',
        'Liquidity mining reward dMSFT-DUSD',
        'Liquidity mining reward dNFLX-DUSD',
        'Liquidity mining reward dVOO-DUSD',
        'Liquidity mining reward dDIS-DUSD',
        'Liquidity mining reward dMCHI-DUSD',
        'Liquidity mining reward dMSTR-DUSD',
        'Liquidity mining reward dINTC-DUSD',
        'Liquidity mining reward dBRK.B-DUSD',
        'Liquidity mining reward dKO-DUSD',
        'Liquidity mining reward dPG-DUSD',
        'Liquidity mining reward dPYPL-DUSD',
        'Liquidity mining reward dCS-DUSD',
        'Liquidity mining reward dSAP-DUSD',
        'Liquidity mining reward dGSG-DUSD',
        'Liquidity mining reward dURA-DUSD',
        'Liquidity mining reward dXOM-DUSD',
        'Liquidity mining reward dGOVT-DUSD',
        'Liquidity mining reward dTAN-DUSD',
        'Liquidity mining reward dPPLT-DUSD',
        'Liquidity mining reward dDAX-DUSD',
        'Liquidity mining reward dJNJ-DUSD',
        'Liquidity mining reward dADDYY-DUSD',
        'Liquidity mining reward dGS-DUSD',
        'Liquidity mining reward dUNG-DUSD',
        'Liquidity mining reward dUSO-DUSD',
        'Liquidity mining reward dWMT-DUSD',
        'Liquidity mining reward dUL-DUSD',
        'Liquidity mining reward USDT-DUSD',
        'Liquidity mining reward USDC-DUSD',
        'Liquidity mining reward UNG-DUSD',
        'Liquidity mining reward USO-DUSD',
        'Liquidity mining reward WMT-DUSD',
        'Liquidity mining reward UL-DUSD',
        'Liquidity mining reward ARKK-DUSD',
        'Liquidity mining reward XLRE-DUSD',
        'Liquidity mining reward VBK-DUSD',
        'Liquidity mining reward XLE-DUSD',
        'Liquidity mining reward NSRGY-DUSD',
        'Liquidity mining reward SHEL-DUSD',
        'Liquidity mining reward SH-DUSD',
        'Liquidity mining reward BITI-DUSD',
        'Liquidity mining reward',
        //ADD_NEW_DTOKEN
      ],
      'Liquidity Mining Rewards'
    )
  );
  //Lapis Rewards
  ergArr.push(
    getSummaryForTransactions(data, year, ['Lapis reward'], 'Lapis Reward')
  );
  //Lapis Rewards
  ergArr.push(
    getSummaryForTransactions(data, year, ['Lending reward'], 'Lending Reward')
  );

  //Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Lapis DFI Bonus'],
      'Lapis DFI Bonus'
    )
  );
  //Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Lending DFI Bonus'],
      'Lending DFI Bonus'
    )
  );
  //Bonus / Airdrops
  ergArr.push(
    getSummaryForTransactions(data, year, ['Bonus/Airdrop'], 'Bonus/Airdrop')
  );
  //Signup Bonus
  ergArr.push(
    getSummaryForTransactions(data, year, ['Signup bonus'], 'Signup Bonus')
  );
  //Referral Signup Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Referral signup bonus'],
      'Referral Signup Bonus'
    )
  );
  //Confectionery Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Confectionery Lapis DFI Bonus'],
      'Conf. Lapis Bonus'
    )
  );
  //Confectionery Lapis DFI Bonus
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Confectionery Lending DFI Bonus'],
      'Conf. Lending Bonus'
    )
  );
  //Referral Reward
  ergArr.push(
    getSummaryForTransactions(data, year, ['Referral reward'], 'Refer. Reward')
  );
  //Referral Reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['DCA referral reward'],
      'DCA refer. Reward'
    )
  );
  //Freezer reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      [
        'Freezer staking bonus',
        '10 years freezer reward',
        '5 years freezer reward',
        'Freezer promotion bonus',
      ],
      'Freezer Bonus'
    )
  );

  //Freezer reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Freezer liquidity mining bonus'],
      'Freezer LM Bonus'
    )
  );
  //Earn reward
  ergArr.push(
    getSummaryForTransactions(data, year, ['Earn reward'], 'Earn reward')
  );

  //Freezer reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['Received ELITE reward booster from Staking'],
      'ELITE reward'
    )
  );
  //Earn reward
  ergArr.push(
    getSummaryForTransactions(
      data,
      year,
      ['YieldVault reward'],
      'YieldVault reward'
    )
  );

  return ergArr;
};
