import {
  CAKE_FILE,
  DEFIAPP_FILE,
  DEFIPORTFOLIO_FILE,
  DEFIAPP_FILE_231,
  DEFIAPP_FILE_232,
  CAKE_FILE_20,
  DEFIPORTFOLIO_FILE_NEW,
} from '../Helper/config.js';

import * as hlp from '../Helper/Helper.js';

/**
 * Returns the array with all headline words
 * @param {string} fileData
 */
export const getHeadlineWords = function (fileData) {
  let lines = fileData.split('\n');
  let headLineStr;
  if (lines) {
    headLineStr = lines.shift();
    if (headLineStr) {
      headLineStr = headLineStr.replace(/"/g, '');
      let headLine = headLineStr.split(',');
      const headLineTrim = headLine.map(str => str.trim());
      return headLineTrim;
    }
  }
  return '';
};

/**
 * Parses the given data
 * @param {*} data - Content of the file which was read in
 * @param {*} typeOfFile - Type of File which was read in
 * @returns
 */
export const parseFile = function (data, typeOfFile) {
  let stakingData;
  if (typeOfFile === CAKE_FILE || typeOfFile === CAKE_FILE_20) {
    stakingData = parseCAKEFile(data);
  }
  if (
    typeOfFile === DEFIAPP_FILE ||
    typeOfFile === DEFIAPP_FILE_231 ||
    typeOfFile === DEFIAPP_FILE_232
  ) {
    stakingData = parseDefiAppFile(data);
  }

  if (
    typeOfFile === DEFIPORTFOLIO_FILE ||
    typeOfFile === DEFIPORTFOLIO_FILE_NEW
  ) {
    stakingData = parseDefiPortfolioFile(data);
  }

  return stakingData;
};

/**
 * Parses the CSV File from Cake and returns a stakingdata array
 * @param {*} data
 */
const parseCAKEFile = function (data) {
  const stakingData = [];
  const headLine = getHeadlineWords(data);
  let lines = data.split('\n');
  lines.shift();
  lines.forEach((element, j) => {
    //Check if there are more characters than a potential "\n"
    if (element.length > 1) {
      let actLineOld;
      let actLine;
      actLineOld = element.split(',');
      actLine = actLineOld.map(str => str.trim());
      const entry = {};
      actLine.forEach((el, i) => {
        if (headLine[i] === 'Date') {
          const entryDate = new Date(el.replace(/"/g, ''));
          entry['Date'] = entryDate;
          return;
        }
        if (headLine[i] === 'Operation') {
          entry['Operation'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Amount') {
          entry['Amount'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (headLine[i] === 'Cryptocurrency' || headLine[i] === 'Coin/Asset') {
          entry['Cryptocurrency'] = el.replace(/"/g, '').replace('/v1', '');
          return;
        }
        if (headLine[i] === 'FIAT value') {
          entry['FiatValue'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (headLine[i] === 'FIAT currency') {
          entry['FiatCurrency'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Transaction ID') {
          entry['TxID'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Withdrawal address') {
          entry['WithdrawalAddress'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Reference') {
          entry['Reference'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Related reference ID') {
          entry['RelatedReference'] = el.replace(/"/g, '');
          return;
        }
      });
      stakingData.push(entry);
    }
  });
  return stakingData;
};

/**
 *
 * @param {*} timerString
 * @returns
 */
const timeConversionSlicker = function (timerString) {
  let AMPM = timerString.slice(-2);
  let timeArr = timerString.slice(0, -2).split(':');
  if (AMPM === 'am' && timeArr[0] === '12') {
    // catching edge-case of 12AM
    timeArr[0] = '00';
  } else if (AMPM === 'pm') {
    // everything with PM can just be mod'd and added with 12 - the max will be 23
    timeArr[0] = (timeArr[0] % 12) + 12;
  }
  return timeArr.join(':');
};

/**
 * Parses the CSV File from DefiAPP and returns a stakingdata array
 * @param {*} data
 * @returns
 */
const parseDefiAppFile = function (data) {
  const stakingData = [];
  const errorLines = [];
  const headLine = getHeadlineWords(data);
  let lines = data.split('\n');

  lines.shift();
  lines.forEach((element, j) => {
    //Check if there are more characters than a potential "\n"
    if (element.length > 1) {
      let actLineOld;
      let actLine;
      actLineOld = element.split(',"');
      actLine = actLineOld.map(str => str.trim());
      const entry = {};
      let isError = false;
      actLine.forEach((el, i) => {
        if (headLine[i] === 'Block Height' || headLine[i] === 'blockHeight') {
          entry['Block'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (headLine[i] === 'Block Hash' || headLine[i] === 'blockHash') {
          entry['BlockHash'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Date/Time') {
          const entryDate = new Date(el.replace(/"/g, '').replace(/\//g, '-'));
          entry['Date'] = entryDate;
          return;
        }
        if (headLine[i] === 'DD/MM/YYYY (Date) / Time') {
          const dateStr = el.replace(/"/g, '');
          const dateArr = dateStr.split('/');
          const timeStr = dateArr[3].trim().substr(0, 5);
          const ampmStr = dateArr[3].trim().substr(6, 2);
          const newTimeStr = `${timeStr}${ampmStr}`;
          const convertStr = timeConversionSlicker(newTimeStr);
          //console.log(`${newTimeStr} - ${convertStr}`);
          const newStr = `${dateArr[2].trim()}-${dateArr[1]}-${
            dateArr[0]
          }T${convertStr}`;
          const entryDate = new Date(newStr);
          //console.log(entryDate);
          entry['Date'] = entryDate;
          return;
        }
        if (headLine[i] === 'Type' || headLine[i] === 'type') {
          const str = el.replace(/"/g, '');
          entry['Operation'] = str;
          return;
        }
        if (headLine[i] === 'Address') {
          const str = el.replace(/"/g, '');
          entry['Owner'] = str;
          return;
        }
        if (headLine[i] === 'Pool ID' || headLine[i] === 'poolID') {
          entry['PoolID'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (headLine[i] === 'Amount' || headLine[i] === 'amounts') {
          const swapEl = el.replace(/"/g, '').split(',');
          if (entry['Operation'] === 'PoolSwap') {
            if (swapEl.length === 2) {
              const newEntry = {};
              const pair1 = swapEl[0].split('@');
              const pair2 = swapEl[1].split('@');
              entry['Amount'] = Number(pair1[0]);
              entry['Cryptocurrency'] = pair1[1].replace('/v1', '');
              newEntry['Amount'] = Number(pair2[0]);
              newEntry['Cryptocurrency'] = pair2[1].replace('/v1', '');
              newEntry['Block'] = entry['Block'];
              newEntry['BlockHash'] = entry['BlockHash'];
              newEntry['Operation'] = entry['Operation'];
              newEntry['PoolID'] = entry['PoolID'];
              const randomVal = hlp.getRandomInt();
              entry['PoolTx'] = randomVal;
              newEntry['PoolTx'] = randomVal;
              newEntry['Date'] = entry['Date'];
              newEntry['Owner'] = entry['Owner'];
              stakingData.push(newEntry);
            } else {
              const pairs = swapEl[0].split('@');
              entry['Amount'] = Number(pairs[0]);
              entry['Cryptocurrency'] = pairs[1].replace('/v1', '');
            }
            return;
          }
          if (entry['Operation'] === 'Commission') {
            if (swapEl.length === 2) {
              const newEntry = {};
              const pair1 = swapEl[0].split('@');
              const pair2 = swapEl[1].split('@');
              entry['Amount'] = Number(pair1[0]);
              entry['Cryptocurrency'] = pair1[1].replace('/v1', '');
              entry['PotentialDublicate'] = true;
              entry['OrigAmount'] = `1_${el.replace(/"/g, '')}`;
              newEntry['Amount'] = Number(pair2[0]);
              newEntry['Cryptocurrency'] = pair2[1].replace('/v1', '');
              newEntry['Block'] = entry['Block'];
              newEntry['BlockHash'] = entry['BlockHash'];
              newEntry['Operation'] = entry['Operation'];
              newEntry['PoolID'] = entry['PoolID'];
              newEntry['Date'] = entry['Date'];
              newEntry['Owner'] = entry['Owner'];
              newEntry['PotentialDublicate'] = true;
              newEntry['OrigAmount'] = `2_${el.replace(/"/g, '')}`;
              stakingData.push(newEntry);
            } else {
              const pairs = swapEl[0].split('@');
              entry['Amount'] = Number(pairs[0]);
              entry['Cryptocurrency'] = pairs[1].replace('/v1', '');
            }
            return;
          }
          if (
            entry['Operation'] === 'AddPoolLiquidity' ||
            entry['Operation'] === 'RemovePoolLiquidity'
          ) {
            if (swapEl.length === 3) {
              const newEntry1 = {};
              const newEntry2 = {};
              const pair1 = swapEl[0].split('@');
              const pair2 = swapEl[1].split('@');
              const pair3 = swapEl[2].split('@');
              const randomVal = hlp.getRandomInt();
              entry['Amount'] = Number(pair1[0]);
              entry['Cryptocurrency'] = pair1[1].replace('/v1', '');
              entry['PoolTx'] = randomVal;
              newEntry1['Amount'] = Number(pair2[0]);
              newEntry1['Cryptocurrency'] = pair2[1].replace('/v1', '');
              newEntry1['Block'] = entry['Block'];
              newEntry1['BlockHash'] = entry['BlockHash'];
              newEntry1['Operation'] = entry['Operation'];
              newEntry1['PoolID'] = entry['PoolID'];
              newEntry1['PoolTx'] = randomVal;
              newEntry1['Date'] = entry['Date'];
              newEntry1['Owner'] = entry['Owner'];
              newEntry2['Amount'] = Number(pair3[0]);
              newEntry2['Cryptocurrency'] = pair3[1].replace('/v1', '');
              newEntry2['Block'] = entry['Block'];
              newEntry2['BlockHash'] = entry['BlockHash'];
              newEntry2['Operation'] = entry['Operation'];
              newEntry2['PoolID'] = entry['PoolID'];
              newEntry2['PoolTx'] = randomVal;
              newEntry2['Date'] = entry['Date'];
              newEntry2['Owner'] = entry['Owner'];
              let strPool = '';
              if (pair1[1].length > 4) {
                strPool = pair1[1];
              } else if (pair2[1].length > 4) {
                strPool = pair2[1];
              } else {
                strPool = pair3[1];
              }
              entry['Pool'] = strPool;
              entry['PoolSuggestion'] = `${strPool}_${randomVal}`;
              newEntry1['PoolSuggestion'] = `${strPool}_${randomVal}`;
              newEntry2['PoolSuggestion'] = `${strPool}_${randomVal}`;
              newEntry1['Pool'] = strPool;
              newEntry2['Pool'] = strPool;
              stakingData.push(newEntry1);
              stakingData.push(newEntry2);
            } else if (swapEl.length === 2) {
              const newEntry = {};

              const pair1 = swapEl[0].split('@');
              const pair2 = swapEl[1].split('@');

              entry['Amount'] = Number(pair1[0]);
              entry['Cryptocurrency'] = pair1[1].replace('/v1', '');
              newEntry['Amount'] = Number(pair2[0]);
              newEntry['Cryptocurrency'] = pair2[1].replace('/v1', '');
              newEntry['Block'] = entry['Block'];
              newEntry['BlockHash'] = entry['BlockHash'];
              newEntry['Operation'] = entry['Operation'];
              newEntry['PoolID'] = entry['PoolID'];
              newEntry['Date'] = entry['Date'];
              newEntry['Owner'] = entry['Owner'];
              let strPool = '';
              let strPoolSug = '';
              if (pair1[1].length > 4) {
                strPoolSug = pair1[1];
                strPool = 'notclear';
              } else if (pair2[1].length > 4) {
                strPoolSug = pair2[1];
                strPool = 'notclear';
              } else {
                if (pair1[1] != 'DFI') {
                  strPoolSug = `${pair1[1]}-DFI`;
                } else {
                  strPoolSug = `${pair2[1]}-DFI`;
                }

                strPool = 'notclear';
              }
              entry['Pool'] = strPool;
              entry['PoolSuggestion'] = strPoolSug;
              newEntry['Pool'] = strPool;
              newEntry['PoolSuggestion'] = strPoolSug;
              stakingData.push(newEntry);
            } else {
              const pairs = swapEl[0].split('@');
              entry['Amount'] = Number(pairs[0]);
              entry['Cryptocurrency'] = pairs[1].replace('/v1', '');
              entry['Pool'] = 'notclear';
              let strPoolSug = '';
              if (pairs[1].length > 4) {
                strPoolSug = pairs[1];
              } else if (pairs[1] != 'DFI') {
                strPoolSug = `${pairs[1]}-DFI`;
              } else {
                strPoolSug = '';
              }
              entry['PoolSuggestion'] = strPoolSug;
            }
            return;
          }

          const pairs = swapEl[0].split('@');
          entry['Amount'] = Number(pairs[0]);
          entry['Cryptocurrency'] = pairs[1].replace('/v1', '');

          return;
        }
      });

      if (!isError) {
        stakingData.push(entry);
      }
    }
  });
  if (errorLines.length > 0) {
    alert(
      'Es gab Fehler in der importierten CSV Datei!\n\nPoolSwap, AddLiquidity oder RemoveLiquidity-Transaktionen sind nicht konsistent!\nAlle fehlerhaften Zeilen werden in der Javascript-Konsole ausgegeben.\n\nGoogle Chrome: Rechte Maustaste - Untersuchen - Reiter Console\nSafari: Enwickler - Javascript Konsole einblenden\n\nDie fehlerhaften Zeilen werden ignoriert und sind nicht in dem Report enthalten!\n\n\nDen Fehler habe ich auf GitHub gemeldet: <<Error in CSV Export #698>>'
    );
    console.error('Inskonsistenzen in der importierten CSV-Datei:\n');
    errorLines.forEach(el => {
      console.error(el);
    });
  }
  return stakingData;
};

/**
 * Parses the CSV File from DefiAPP and returns a stakingdata array
 * @param {*} data
 * @returns
 */
const parseDefiPortfolioFile = function (data) {
  const headLine = getHeadlineWords(data);
  let lines = data.split('\n');
  const stakingData = [];
  lines.shift();
  lines.forEach((element, j) => {
    //Check if there are more characters than a potential "\n"
    if (element.length > 1) {
      let actLineOld;
      let actLine;
      actLineOld = element.split(',');
      actLine = actLineOld.map(str => str.trim());
      const entry = {};
      actLine.forEach((el, i) => {
        if (headLine[i] === 'Date' || headLine[i] === 'blockTimeColumn') {
          const entryDate = new Date(el.replace(/"/g, ''));
          entry['Date'] = entryDate;
          return;
        }
        if (
          headLine[i] === 'Amount' ||
          headLine[i] === 'Operation' ||
          headLine[i] === 'typeColumn'
        ) {
          entry['Operation'] = el.replace(/"/g, '');
          return;
        }
        if (
          headLine[i] === 'Crypto value' ||
          headLine[i] === 'cryptoValueColumn'
        ) {
          entry['Amount'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (
          headLine[i] === 'Crypto currency' ||
          headLine[i] === 'cryptoCurrencyColumn'
        ) {
          entry['Cryptocurrency'] = el.replace(/"/g, '').replace('/v1', '');
          return;
        }
        if (headLine[i] === 'FIAT value' || headLine[i] === 'fiatValueColumn') {
          entry['FiatValue'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (
          headLine[i] === 'FIAT currency' ||
          headLine[i] === 'fiatCurrencyColumn'
        ) {
          entry['FiatCurrency'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Pool Pair' || headLine[i] === 'poolIDColumn') {
          entry['PoolID'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (
          headLine[i] === 'Block Height' ||
          headLine[i] === 'blockHeightColumn'
        ) {
          entry['Block'] = Number(el.replace(/"/g, ''));
          return;
        }
        if (headLine[i] === 'Block Hash' || headLine[i] === 'blockHashColumn') {
          entry['BlockHash'] = el.replace(/"/g, '');
          return;
        }
        if (headLine[i] === 'Owner' || headLine[i] === 'ownerColumn') {
          entry['Owner'] = el.replace(/"/g, '');
          return;
        }
        if (
          headLine[i] === 'Transaction Hash' ||
          headLine[i] === 'transactionColumn'
        ) {
          entry['TxID'] = el.replace(/"/g, '');
          return;
        }
      });
      stakingData.push(entry);
    }
  });
  return stakingData;
};
