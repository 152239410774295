import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateYieldVaultCakeActions = function (data, grouping) {
  let objData = [];
  hlp.logconsole('Generating Cake Yield Vault Actions');

  const enterYieldVaultActions = data.filter(
    el => el.Operation === 'Entered YieldVault'
  );

  const exitYieldVaultActions = data.filter(
    el => el.Operation === 'Exited YieldVault'
  );

  const adjustYieldVaultActions = data.filter(
    el => el.Operation === 'Adjusted YieldVault entry'
  );

  //Direct enter to staking wallet actions from lending bonus
  enterYieldVaultActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE - YieldVault Wallet',
        'YieldVault',
        `${el.Operation} - Deposit to YieldVault wallet`,
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE',
        'YieldVault',
        'Entry YieldVault wallet - withdraw from normal wallet',
        el.Date,
        `3_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //Withdraw to Staking-Wallet-Actions
  exitYieldVaultActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE - YieldVault Wallet',
        'YieldVault',
        'Exit YieldVault wallet - withdraw from YieldVault wallet',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'YieldVault',
        `${el.Operation} - Deposit to normal wallet`,
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  adjustYieldVaultActions.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Adjust YieldVault entry',
        `${el.Operation} - Deposit to wallet`,
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}_TxID:${
          el.TxID
        }`
      )
    );
  });

  //console.log(objData);
  return objData;
};
