import * as hlp from '../Helper/Helper.js';
import {
  CAKE_FILE,
  DEFIAPP_FILE,
  DEFIPORTFOLIO_FILE,
  DEFIAPP_FILE_231,
  DEFIAPP_FILE_232,
  CAKE_FILE_20,
  DEFIPORTFOLIO_FILE_NEW,
  DFITAX_HISTORY_FILE,
  DFITAX_REWARD_FILE,
} from '../Helper/config.js';
import * as parser from './FileParser.js';

export const readFile = async function (file) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    let isError = false;
    let stringData;

    fileReader.onloadstart = function (progressEvent) {
      isError = false;
      hlp.logconsole('Start reading file...');
      hlp.logconsole(`File name: ${file.name}`);
      hlp.logconsole(`File size: ${file.size} Byte`);
      hlp.logconsole(`File type: ${file.type}`);

      if (!hlp.checkIsCSVFile(file.name)) {
        hlp.logconsole(`Caution: File has no csv-ending. Continue anyway...`);
      }
    };

    fileReader.onload = function (progressEvent) {
      hlp.logconsole('Loading...');
      stringData = fileReader.result;
      const testStr = stringData.charAt(stringData.length - 1);
      if (testStr === '\n') {
        stringData = stringData.substr(0, stringData.length - 1);
      }
    };

    fileReader.onerror = function (progressEvent) {
      appendLog('Error reading file!');
    };
    fileReader.onabort = function (progressEvent) {
      appendLog(`Falsches Dateiformat`);
      alert('Invalid File-Type!');
    };
    fileReader.onloadend = resolve;
    fileReader.readAsText(file);
  });
};

/**
 * Returns the value for the filetype
 * @param {String} fileContent
 */
export const checkFileType = function (fileContent) {
  const headLine = parser.getHeadlineWords(fileContent);
  const lenHead = headLine.length;
  if (lenHead > 0) {
    //DEFI-APP File
    if (
      headLine[0].toLowerCase() === 'block height' &&
      headLine[2].toLowerCase() === 'date/time'
    ) {
      return DEFIAPP_FILE_231;
    }
    if (
      headLine[0].toLowerCase() == 'blockheight' &&
      headLine[2].toLowerCase() === 'type'
    ) {
      return DEFIAPP_FILE;
    }
    if (
      headLine[0] === 'Block Height' &&
      headLine[2] === 'DD/MM/YYYY (Date) / Time'
    ) {
      return DEFIAPP_FILE_232;
    }

    if (headLine[0] === 'blockTimeColumn' && headLine[1] === 'typeColumn') {
      return DEFIPORTFOLIO_FILE_NEW;
    }
    if (lenHead >= 7) {
      //CAKE FILE
      if (
        headLine[1].toLowerCase() === 'operation' &&
        headLine[6].toLowerCase() === 'transaction id' &&
        headLine[3] === 'Cryptocurrency'
      ) {
        return CAKE_FILE;
      }
      //CAKE20 File
      if (
        headLine[1].toLowerCase() === 'operation' &&
        headLine[3] === 'Coin/Asset'
      ) {
        return CAKE_FILE_20;
      }
      //DEFI-PORTFOLIO FILE
      if (
        headLine[6].toLowerCase() === 'pool pair' ||
        headLine[6].toLowerCase() === 'pool id'
      ) {
        return DEFIPORTFOLIO_FILE;
      }
    }
  }
  throw 'Invalid file. Header is wrong...';
};

/**
 * Function to convert the csv file provided from cake to JSON
 * Informations are splitted to the fields and names provided by the headline
 * of the csv File
 * @param {string} csvData - Content of the File to convert to JSON
 * @param {number} typeOfFile - typeOfFile
 */
export const processFile = function (csvData, typeOfFile) {
  return parser.parseFile(csvData, typeOfFile);
};
