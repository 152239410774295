import * as hlp from '../Helper/Helper.js';
/**
 * Parses the response from the DFI.TAX API Call for the rewards
 * @param {Array} data - raw data from api call
 */
export const parseDfiTaxRewardAPICall = function (data, currency) {
  const stakingData = [];

  data.forEach((el, j) => {
    const entry = {};
    let actionDate = new Date(el.date);
    actionDate.setTime(
      actionDate.getTime() - actionDate.getTimezoneOffset() * 60 * 1000
    );
    entry['Date'] = actionDate;
    entry['Operation'] = el.category;
    entry['Pool'] = el.pool;
    entry['Cryptocurrency'] = el.token.replace('/v1', '');
    entry['Amount'] = el.qty;
    entry['FiatCurrency'] = el.cur_code;
    entry['FiatValue'] = (el.value_close + el.value_open) / 2;
    stakingData.push(entry);
  });

  return stakingData;
};

/**
 * This method "cleans" the transaction history and the unnecessary Blockchain transactions
 * @param {Array} data - Complete data which was read out from the dfi.tax api
 * @returns the compressed data
 */
const cleanupHistoryData = function (data) {
  const retData = [];
  //1.) Get a Set with all TxIDs we have in the history-result
  let listOfTxIds = new Set();
  data.forEach(elem => {
    listOfTxIds.add(elem.TxID);
  });

  //2.) Get through the list of all blockchain-transactions to filter the data
  listOfTxIds.forEach(txId => {
    let actions = data.filter(entry => entry.TxID === txId);

    //2.a.) Actions with length 1 are ready because it is a "real" receive action
    if (actions.length === 1) {
      retData.push(...actions);
    } else if (actions.length === 2) {
      let sum = 0;
      let fiatSum = 0;
      actions.forEach(el => {
        sum += el.Amount;
        fiatSum += el.FiatValue;
      });
      let tempObj = {};
      if (sum > 0) {
        tempObj.Operation = 'receive';
      } else {
        tempObj.Operation = 'sent';
      }
      tempObj.Amount = sum;
      tempObj.FiatValue = fiatSum;
      tempObj.Block = actions[0].Block;
      tempObj.Cryptocurrency = actions[0].Cryptocurrency;
      tempObj.Date = actions[0].Date;
      tempObj.Fee = actions[0].Fee;
      tempObj.FeeCurrency = actions[0].FeeCurrency;
      tempObj.TxID = actions[0].TxID;
      retData.push(tempObj);
    } else {
      let sum = 0;
      let fiatSum = 0;
      let bFound = false;
      let tempActions = [];
      actions.forEach(el => {
        if (
          el.Operation != 'sent' &&
          el.Operation != 'receive' &&
          el.Operation != 'AccountToUtxos' &&
          el.Operation != 'UtxosToAccount'
        ) {
          tempActions.push(el);
          bFound = true;
        } else {
          sum += el.Amount;
          fiatSum += el.FiatValue;
        }
      });
      let tempObj = {};
      if (sum > 0) {
        tempObj.Operation = 'receive';
      } else {
        tempObj.Operation = 'sent';
      }
      tempObj.Amount = sum;
      tempObj.FiatValue = fiatSum;
      tempObj.Block = actions[0].Block;
      tempObj.Cryptocurrency = actions[0].Cryptocurrency;
      tempObj.Date = actions[0].Date;
      tempObj.Fee = actions[0].Fee;
      tempObj.FeeCurrency = 'DFI';
      tempObj.TxID = actions[0].TxID;

      if (bFound) {
        tempActions.forEach(el => {
          el.Fee = Math.abs(tempObj.Amount);
          el.FeeCurrency = tempObj.FeeCurrency;
        });
        retData.push(...tempActions);
      } else {
        retData.push(tempObj);
      }
    }
  });

  return retData;
};

/**
 * Parses the response from the DFI.TAX API Call for the history
 * @param {Array} data - raw data from api call
 */

export const parseDfiTaxHistoryAPICall = function (data, currency) {
  const stakingData = [];

  data.forEach((el, j) => {
    const entry = {};
    let actionDate = new Date(el.dt);
    actionDate.setTime(
      actionDate.getTime() - actionDate.getTimezoneOffset() * 60 * 1000
    );

    entry['Date'] = actionDate;
    if (el.cat === 'RECEIVE') {
      entry['Operation'] = 'receive';
    } else if (el.cat === 'SEND') {
      entry['Operation'] = 'sent';
    } else {
      entry['Operation'] = el.cat;
    }
    entry['Fee'] = el.fee_qty;
    entry['FeeCurrency'] = 'DFI';
    entry['Block'] = el.blk_id;
    entry['TxID'] = el.tx_id;
    entry['FiatCurrency'] = currency;

    if (el.cat === 'RECEIVE') {
      if (el.tokens.length === 2) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'receive';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[1].qty;
        newEntry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[1].value;
        newEntry['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
        stakingData.push(newEntry);
      } else {
        entry['Pool'] = '';
        entry['Owner'] = '';
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (el.cat === 'SEND') {
      if (el.tokens.length === 2) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'receive';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[1].qty;
        newEntry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[1].value;
        newEntry['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
        stakingData.push(newEntry);
      } else {
        entry['Pool'] = '';
        entry['Owner'] = '';
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (el.cat === 'AnyAccountsToAccounts') {
      entry['Operation'] = 'AnyAccountsToAccounts';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
      entry['FiatValue'] = el.tokens[0].value;
    } else if (el.cat === 'AccountToAccount') {
      entry['Operation'] = 'AccountToAccount';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
      entry['FiatValue'] = el.tokens[0].value;
    } else if (el.cat === 'PoolSwap') {
      if (el.tokens.length === 2) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = el.cat;
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[0].qty;
        newEntry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[0].value;
        newEntry['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[1].qty;
        entry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[1].value;
        stakingData.push(newEntry);
      } else {
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (
      el.cat === 'AddPoolLiquidity' ||
      el.cat === 'RemovePoolLiquidity'
    ) {
      if (el.tokens.length === 3) {
        const newEntry1 = {};
        const newEntry2 = {};
        newEntry1['Date'] = actionDate;
        newEntry2['Date'] = actionDate;
        newEntry1['Operation'] = el.cat;
        newEntry2['Operation'] = el.cat;
        newEntry1['Block'] = el.blk_id;
        newEntry2['Block'] = el.blk_id;
        newEntry1['TxID'] = el.tx_id;
        newEntry2['TxID'] = el.tx_id;
        newEntry1['Amount'] = el.tokens[0].qty;
        newEntry2['Amount'] = el.tokens[1].qty;
        newEntry1['FiatValue'] = el.tokens[0].value;
        newEntry2['FiatValue'] = el.tokens[1].value;
        newEntry1['FiatCurrency'] = currency;
        newEntry2['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[2].qty;
        entry['FiatValue'] = el.tokens[2].value;

        newEntry1['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        newEntry2['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        entry['Cryptocurrency'] = el.tokens[2].code.replace('/v1', '');

        let strPool;
        for (let i = 0; i < el.tokens.length; i++) {
          if (el.tokens[i].code.indexOf('-') != -1) {
            strPool = el.tokens[i].code;
          }
        }
        const randomVal = hlp.getRandomInt();
        entry['Pool'] = strPool;
        newEntry1['Pool'] = strPool;
        newEntry2['Pool'] = strPool;
        entry['PoolSuggestion'] = `${strPool}_${randomVal}`;
        newEntry1['PoolSuggestion'] = `${strPool}_${randomVal}`;
        newEntry2['PoolSuggestion'] = `${strPool}_${randomVal}`;

        stakingData.push(newEntry1);
        stakingData.push(newEntry2);
      } else if (el.tokens.length === 2) {
        const newEntry1 = {};
        newEntry1['Date'] = actionDate;
        newEntry1['Operation'] = el.cat;
        newEntry1['Block'] = el.blk_id;
        newEntry1['TxID'] = el.tx_id;
        newEntry1['Amount'] = el.tokens[0].qty;
        entry['Amount'] = el.tokens[1].qty;
        newEntry1['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[1].value;
        newEntry1['FiatValue'] = el.tokens[0].value;
        let strPool;
        for (let i = 0; i < el.tokens.length; i++) {
          if (el.tokens[i].code.indexOf('-') != -1) {
            strPool = el.tokens[i].code;
          }
        }
        const randomVal = hlp.getRandomInt();
        entry['Pool'] = strPool;
        newEntry1['Pool'] = strPool;

        entry['PoolSuggestion'] = `${strPool}_${randomVal}`;
        newEntry1['PoolSuggestion'] = `${strPool}_${randomVal}`;
        stakingData.push(newEntry1);
      } else {
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (el.cat === 'COINBASE') {
      //Dublicate, because "Coinbase" is part of the reward history
      return;
    } else if (el.cat === 'FutureSwapExecution') {
      entry['Pool'] = '';
      entry['Owner'] = '';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code;
      entry['FiatValue'] = el.tokens[0].value;
      //Maybe we have a lot of different actions within the TokenSplit-Transaction
      for (let i = 1; i < el.tokens.length; i++) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'FutureSwapExecution';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[i].qty;
        newEntry['Cryptocurrency'] = el.tokens[i].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[i].value;
        newEntry['FiatCurrency'] = currency;
        stakingData.push(newEntry);
      }
    } else if (el.cat === 'TakeLoan') {
      if (el.tokens.length === 2) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'TakeLoan';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[1].qty;
        newEntry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[1].value;
        newEntry['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
        stakingData.push(newEntry);
      } else {
        entry['Pool'] = '';
        entry['Owner'] = '';
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (el.cat === 'PaybackLoan') {
      if (el.tokens.length === 2) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'PaybackLoan';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[1].qty;
        newEntry['Cryptocurrency'] = el.tokens[1].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[1].value;
        newEntry['FiatCurrency'] = currency;
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
        stakingData.push(newEntry);
      } else {
        entry['Pool'] = '';
        entry['Owner'] = '';
        entry['Amount'] = el.tokens[0].qty;
        entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
        entry['FiatValue'] = el.tokens[0].value;
      }
    } else if (el.cat === 'TokenSplit') {
      entry['Pool'] = '';
      entry['Owner'] = '';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code;
      entry['FiatValue'] = 0;
      //Maybe we have a lot of different actions within the TokenSplit-Transaction
      for (let i = 1; i < el.tokens.length; i++) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'TokenSplit';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[i].qty;
        newEntry['Cryptocurrency'] = el.tokens[i].code.replace('/v1', '');
        newEntry['FiatValue'] = 0;
        newEntry['FiatCurrency'] = currency;
        stakingData.push(newEntry);
      }
    } else if (el.cat === 'CloseVault') {
      entry['Pool'] = '';
      entry['Owner'] = '';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code;
      entry['FiatValue'] = el.tokens[0].value;
      //Maybe we have a lot of different actions within the TokenSplit-Transaction
      for (let i = 1; i < el.tokens.length; i++) {
        const newEntry = {};
        newEntry['Date'] = actionDate;
        newEntry['Operation'] = 'CloseVault';
        newEntry['Block'] = el.blk_id;
        newEntry['TxID'] = el.tx_id;
        newEntry['Amount'] = el.tokens[i].qty;
        newEntry['Cryptocurrency'] = el.tokens[i].code.replace('/v1', '');
        newEntry['FiatValue'] = el.tokens[i].value;
        newEntry['FiatCurrency'] = currency;
        stakingData.push(newEntry);
      }
    } else {
      entry['Pool'] = '';
      entry['Owner'] = '';
      entry['Amount'] = el.tokens[0].qty;
      entry['Cryptocurrency'] = el.tokens[0].code.replace('/v1', '');
      entry['FiatValue'] = el.tokens[0].value;
    }
    stakingData.push(entry);
  });

  return cleanupHistoryData(stakingData);
};
