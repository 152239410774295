import * as dateHelper from '../Helper/DateHelper.js';
import * as Actions from '../Actions/Actions.js';
import * as hlp from '../Helper/Helper.js';

/**
 * Generates the report data obj for all internal trade elements
 * @param {Array} data - Data to be analysed
 * @returns returns the objs
 */
export const generateCakeVaultsActions = function (data) {
  let objData = [];
  hlp.logconsole('Generating CakeVault Actions');

  const addCollateral = data.filter(el => el.Operation === 'Add collateral');
  const removeCollateral = data.filter(
    el => el.Operation === 'Remove collateral'
  );
  const borrowAssets = data.filter(el => el.Operation === 'Borrow asset');
  const borrowAssetsFee = data.filter(
    el => el.Operation === 'Pay borrowing fees'
  );
  const repayBorrowAssets = data.filter(
    el => el.Operation === 'Repay Borrowed Assets'
  );

  //Add collateral to Vault
  addCollateral.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE',
        'Add collateral - Withdraw from CAKE',
        'Add collateral',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE - Vault',
        'Add collateral - Deposit to CAKE - Vault',
        'Add collateral',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
  });

  //Remove collateral to Vault
  removeCollateral.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE - Vault',
        'Remove collateral - Withdraw from CAKE - Vault',
        'Remove collateral',
        el.Date,
        `1_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Remove collateral - Deposit to CAKE',
        'Remove collateral',
        el.Date,
        `2_${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
  });

  borrowAssets.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Deposit',
        'deposit',
        'deposit',
        'add funds',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        '',
        '',
        'CAKE',
        'Borrow Assets - Minted Token',
        'Borrow Assets',
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
  });

  borrowAssetsFee.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Other Fee',
        'gift',
        'fee',
        'withdraw',
        '',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        'CAKE',
        'Borrow Assets Fee',
        'Borrow Assets Feee',
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
  });

  repayBorrowAssets.forEach(el => {
    objData.push(
      Actions.buildEntryObj(
        'Withdrawal',
        'withdrawal',
        'withdraw',
        '',
        '',
        '',
        Math.abs(el.Amount).toFixed(14),
        el.Cryptocurrency,
        '',
        '',
        'CAKE',
        'Repay Borrowed Assets',
        'Repay Borrowed Assets',
        el.Date,
        `${el.Cryptocurrency}${dateHelper.getTxIdDateString(el.Date)}`
      )
    );
  });
  return objData;
};
