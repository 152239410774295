import * as hlp from '../Helper/Helper.js';
import * as Actions from '../Actions/Actions.js';
import * as dateHelper from '../Helper/DateHelper.js';
/**
 * This function generates all actions for the TokenSplits
 * @param {Array} data - Data to be analysed
 * @returns returns the list of StockSplit obj
 */
export const generateCakeStockSplits = function (data) {
  let objData = [];
  hlp.logconsole('Generating Cake Stock Split Actions');

  //List with all TokenSplit ingoing entries
  let stockSplitInActionsArray = data.filter(
    el => el.Operation === 'Token split ingoing'
  );

  //List with all TokenSplit outgoing entries
  let stockSplitOutActionsArray = data.filter(
    el => el.Operation === 'Token split outgoing'
  );

  let stockSplitActionsArray = [
    ...stockSplitInActionsArray,
    ...stockSplitOutActionsArray,
  ];

  //Set erstellen mit allen coins
  const coinList = new Set();
  stockSplitActionsArray.forEach(el => {
    coinList.add(el.Cryptocurrency);
  });

  //console.log(coinList);
  coinList.forEach(el => {
    let entryArr = stockSplitActionsArray.filter(
      entry => entry.Cryptocurrency === el
    );
    //console.log(entryArr);
    if (entryArr.length === 2) {
      let inAmount = entryArr[0].Amount + entryArr[1].Amount;
      //Match found
      objData.push(
        Actions.buildEntryObj(
          'Income (non taxable)',
          'deposit',
          'deposit',
          '',
          inAmount,
          el,
          '',
          '',
          '',
          '',
          'DeFiChain Wallet',
          'TokenSplit',
          'TokenSplit',
          entryArr[0].Date,
          `${el}_TokenSplit_${dateHelper.getTxIdDateString(
            entryArr[0].Date
          )}_TxID:${entryArr[0].TxID}`,
          ''
        )
      );
    }
  });
  return objData;
};
